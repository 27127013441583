$(document).ready(function() {
  $.ajaxSetup({
    cache: false,
    headers: {
      'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache'
    }
  });

  if ($("input[name='DeleteMapNumber']").length) {
    ToggleVisibilityDeleteFilterMapButton();

    $("input[name='DeleteMapNumber']").on('change keyup paste', function() {
      ToggleVisibilityDeleteFilterMapButton();
    });
  }
});

function ToggleVisibilityDeleteFilterMapButton() {
  var inputText = $("input[name='DeleteMapNumber']").val();
  inputText = inputText.trim();

  if (inputText === '') {
    $('#DeleteMapNumberBtn').prop('disabled', true);
  } else {
    $('#DeleteMapNumberBtn').prop('disabled', false);
  }
}

//////////
// Home Carousel
//////////

$('.carousel').owlCarousel({
  navigation: true, // Show next and prev buttons
  navigationText: [
    '<i class="fa fa-angle-left"></i>',
    '<i class="fa fa-angle-right"></i>'
  ],
  slideSpeed: 300,
  paginationSpeed: 300,
  singleItem: true
});

//////////
// Menu - Splitted Categories
//////////

$('.js-activated').mouseenter(function() {
  var subLevelContent = $(this).nextAll('.megadropdown');

  if (!$(subLevelContent).hasClass('splitted')) {
    var bigList = $('>ul', subLevelContent);
    var classes = bigList.attr('class');
    var tempContainer = $('<div/>');
    var newList = $('<ul/>').addClass(classes);
    var liColumnCount = Math.floor($('>li li', bigList).length / 3);
    var liCount = 0;

    if (liColumnCount < 6) liColumnCount = 6;

    $('>li', bigList).each(function(index) {
      if (liCount >= liColumnCount) {
        $(tempContainer).append(newList);

        liCount = $('li', $(this)).length;
        newList = $('<ul/>').addClass(classes);
        $(newList).append($(this));
      } else {
        liCount += $('li', $(this)).length;
        $(newList).append($(this));
      }
    });

    $(tempContainer).append(newList);

    $(bigList).replaceWith(tempContainer.html());
    $(subLevelContent).addClass('splitted');
  }
});

//////////
// Set the max height to all ELEMENTS in an container
//////////

$.fn.setAllToMaxHeight = function() {
  return this.height(
    Math.max.apply(
      this,
      $.map(this, function(e) {
        return $(e).height();
      })
    )
  );
};

//////////
// Random ..
//////////

$(window).smartresize(function() {
  $('.ContainerName')
    .css('height', '')
    .setAllToMaxHeight();
  $('#start-banners .banner-md .caption')
    .css('height', '')
    .setAllToMaxHeight();
  $('#start-banners .display-caption')
    .css('height', '')
    .setAllToMaxHeight();
  $('.reg-box, .login-box')
    .css('height', '')
    .setAllToMaxHeight();
});

$(function() {
  $('.ContainerName').setAllToMaxHeight();
  $('#start-banners .banner-md .caption').setAllToMaxHeight();
  $('#start-banners .display-caption').setAllToMaxHeight();
  $('.reg-box, .login-box').setAllToMaxHeight();

  // Register Banner Collapse

  $('#registrateUserButton2').click(function() {
    $(this).hide();
    $('.login-box').css('height', '125%');
    $('#submitter')
      .removeClass('hidden')
      .show();
    $('#registerContainer').slideDown();
  });

  // Tooltip handling
  $('.cart-tooltip').tooltip();

  // Ansök button ..
  $('.formtable')
    .find('input[type="button"]')
    .addClass('btn btn-default');

  // Fixing the # links ..
  $('a[href="#"]').click(function(e) {
    e.preventDefault();
  });

  // Fixing the modal z-index issue because #Slidebars
  $('#filterModal').on('hidden.bs.modal', function(e) {
    $('#sb-site').css('z-index', 1);
  });

  $('#filterModal').on('shown.bs.modal', function(e) {
    $('#sb-site').css('z-index', 'inherit');
    if ($('#sb-site').css('-webkit-transform', 'translate(0px)')) {
      $('#sb-site').css('-webkit-transform', 'inherit');
    }
  });

  // lazyload

  // Since iPad freeze DOM while scrolling lazy effect gets very slow for users, remove when under iPad landscape size
  if ($(window).width() <= 1024) {
    $('img.lazy').each(function() {
      $(this).attr('src', $(this).attr('data-original'));
      $(this).removeAttr('data-original');
      $(this).removeClass('lazy');
    });
  }
  $('.lazy')
    .show()
    .lazyload({
      effect: 'fadeIn'
    });

  // Cookies bar
  $('.cookiebar').cookieBar({ closeButton: '.cClose' });

  // Filter check items
  $('.filter-checked-false').click(function() {
    $(this)
      .find('i')
      .addClass('i-checked');
  });

  $('.filter-checked-true').click(function() {
    $(this)
      .find('i')
      .addClass('i-unChecked');
  });

  // Hide the empty filters
  $('.filter-control div.col-xs-6').each(function() {
    if ($(this).is('#Supplier-filter')) {
      if ($(this).find('.list-group label').length == 0) {
        $(this).hide();
      }
    }
  });

  // Show the selected filter group
  $('.filter-control div.col-xs-6').each(function() {
    var selected = $(this)
      .find('label input')
      .is(':checked');
    if (selected) {
      $(this)
        .find('.collapse-me')
        .show();
    }
  });

  // Scroll to the results when you select an filter
  var productList = $('.Produkter .content > .products-list');
  var includedFilter = window.location.href.includes('?');
  if (productList.length && includedFilter) {
    $('html, body').animate(
      {
        scrollTop: productList.offset().top - 50 + 'px'
      },
      200
    );
  }
});

// Product list - Responsive Equal height
$(window).load(function() {
  $('div.display-name').setAllToMaxHeight();
});
$(window).resize(function() {
  $('div.display-name').setAllToMaxHeight();
});

// Basket
// ------------------
$('.basket-dropdown').appendTo('.header-content');
$('#basket-btn').click(function() {
  $('.basket-dropdown').toggle();
});

//////////
// Responsive function to handle all the responsive issues
//////////

function ResponsiveChanges() {
  // backToTop button in the small devices..
  $(window).scroll(function() {
    if ($(this).scrollTop() > 100) {
      $('#back-top').fadeIn();
    } else {
      $('#back-top').fadeOut();
    }
  });

  // scroll body to 0px on click
  $('#back-top').click(function() {
    $('body,html').animate(
      {
        scrollTop: 0
      },
      500
    );
    return false;
  });

  // Tablet landscape
  if ($(window).width() < 992) {
    $(function() {
      $('.orderlines-container .orderlinetable').removeClass('table-hover');
      $('.cart-tooltip').removeClass('cart-tooltip');
    });
  }

  // Tablet vertical
  if ($(window).width() < 767) {
    $(function() {
      // The side Menu Using #Slidebars plugin
      $.slidebars();
      $('#sb-site').css('minHeight', $('html').height() + 'px');

      $('#moms-menu-small').html($('#moms-menu-big').html());

      // Fixing the basket width and position problem

      // var wrapp = $(window).width() - 10;
      // var posX = 0 - $(window).width() / 2;
      // $('.basket').width(wrapp - 40).css('left', posX);

      // Append the main menu to the slidebar container
      $('.nav-primary')
        .appendTo('.sb-slidebar')
        .show();

      // Replacing the submenu to Select object
      $('.submenu .visible-xs').bind('change', function() {
        var url = $(this).val(); // get selected value
        if (url) {
          // require a URL
          window.location = url; // redirect
        }
        return false;
      });

      // Search box appened to the small box
      $('#search-box').appendTo('#search-box-small');
    });
  }

  // mobile
  if ($(window).width() < 480) {
    // Append the filter to the Modal - Responsive part
    $(function() {
      $('.filter-control').appendTo('.modal-filter-control');

      //$(".panzoom-elements").panzoom();
      $('.panzoom-elements').panzoom({
        disablePan: true,
        maxScale:
          $('.panzoom-elements').naturalWidth /
          $('.panzoom-elements').clientWidth
      });

      $('.panzoom-elements').on('panzoomend', function(
        e,
        panzoom,
        matrix,
        changed
      ) {
        $('.panzoom-elements').panzoom('option', 'disablePan', false);
      });

      // Moving the pages links to the primary Menu
      $('.resp .top-links-item').wrap('<li></li>');
      var KontaktElement = $(
        ".resp .top-links-item:contains('Kontakt')"
      ).parent();
      $('.navbar-default .navbar-nav').prepend(KontaktElement);
      var topLinksElements = $('.resp .top-links-item').parent();
      $('.navbar-default .navbar-nav').append(topLinksElements);

      // Fixing the basket width and position problem
      // var wrapp = $(window).width() - 10;
      // var posX = 0 - $(window).width() / 2 + 35 ;
      // $('.basket').width(wrapp).css('left', posX);
    });
  }

  // Print version: clearing the float elements to break the pages ..
  if (window.matchMedia) {
    var printMedia = window.matchMedia('print');
    printMedia.addListener(function(mql) {
      if (mql.matches) {
        // select the elements to append the break div after ..
        var first2 = $(
          '.products-page .productlist-images .col-xs-6:nth-child(2)'
        );
        var first10 = $(
          '.products-page .productlist-images .col-xs-6:nth-child(10)'
        );
        var first18 = $(
          '.products-page .productlist-images .col-xs-6:nth-child(18)'
        );
        var first26 = $(
          '.products-page .productlist-images .col-xs-6:nth-child(26)'
        );
        var isolist6 = $('#list-page-list > li:nth-child(6)');

        // append the break div..
        $(first2).after('<div class="print-break"></div>');
        $(first10).after('<div class="print-break"></div>');
        $(first18).after('<div class="print-break"></div>');
        $(first26).after('<div class="print-break"></div>');
        $(isolist6).after('<div class="print-break"></div>');

        $('.list-item').each(function() {
          // Store the original attribute data ..
          $(this).data('styleData', $(this).attr('style'));
          // Manupilating the attribute ..
          $(this).attr('style', '');
        });

        $('.y-map').show();
      } else {
        $('.print-break').remove();

        $('.list-item').each(function() {
          // Restore the original attribute data ..
          $(this).attr('style', $(this).data('styleData'));
        });
      }
    });
  }
}
ResponsiveChanges();

//////////
// Carousels thumbnails and lightbox carousel
//////////

$('#carousel-text').html($('#slide-content-0').html());

//Handles the carousel thumbnails
$('[id^=carousel-selector-]').click(function() {
  var id_selector = $(this).attr('id');
  var id = id_selector.substr(id_selector.length - 1);
  var id = parseInt(id);
  $('#carousel-productimages').carousel(id);
});

// Append loaded slider images into the carousel's lightbox
function ProductCarousel() {
  var imgLoad = $('#carousel-productimages .item img');

  imgLoad
    .on('load', function() {})
    .each(function(i) {
      if (this.complete) {
        var item = $('<div class="item"></div>');
        var itemDiv = $(this).parents('div');
        var title = $(this)
          .parent('div')
          .attr('title');
        var src = $(this).attr('src');
        // High Res version
        var hires = $(this)
          .parent('div')
          .data('hires');

        item.attr('title', title);
        $(this).attr('src', hires);
        $(itemDiv.html()).appendTo(item);
        item.appendTo('#modalCarousel .carousel-inner');
        $(this).attr('src', src);
      }
    });
}

// When its loaded ..
$('#carousel-productimages .item img').imagesLoaded(function() {
  ProductCarousel();
});

//////////
// Product page - When clicking the zoom ..
//////////

$('#y-zoom').click(function() {
  (function() {
    var activeItem = $('.item.active');
    var id = $('#carousel-productimages .carousel-inner .item').index(
      activeItem
    );

    var mID = $('#modalCarousel .carousel-inner .item').eq(id);

    if ($('#myModal2').modal('show')) {
      if ($('#modalCarousel .carousel-inner .item').hasClass('active')) {
        $('#modalCarousel .carousel-inner .item').removeClass('active');
        mID.addClass('active');
      } else {
        mID.addClass('active');
      }
    }
  })();

  /* activate the carousel */
  $('#modalCarousel').carousel({ interval: false });
  $('#myModal2')
    .modal()
    .fadeIn(); // show the modal
});

// Product page - Image download button ..

$('#download').on('click', function() {
  var src = $('.item.active')
    .children('img')
    .attr('src');
  var title = $('.item.active')
    .children('img')
    .attr('title');
  $(this).attr('href', src);
  $(this).attr('download', title);
  $(this).click();
});

//////////
// Läs mer lösning ..
//////////

(function() {
  var firstP = $('.lead div, .lead p');
  if (firstP.size() > 1) {
    $('.las-mer').show();
    firstP.show();
    firstP.slice(1).appendTo('.intro-black-collapse');
  } else {
    firstP.show();
  }
})();

//////////
// Close elements when click outside
//////////

$(document).click(function(e) {
  if (!$(e.target).is('.basket, .basket *, .basket-links *')) {
    $('.basket').hide();
  }
  if (
    !$(e.target).is(
      '#search-results, #search-results *, .dw-instant-search, .search-icon, .search-icon *'
    )
  ) {
    $('#search-results').hide();
  }

  if (!$(e.target).is('.ax-message, .ax-message *')) {
    $('.ax-message').css('top', -46);
  }
});

$('.b-close').click(function() {
  $('.basket').hide();
});

//  Detecting the touch devices
var deviceAgent = navigator.userAgent.toLowerCase();

var isTouchDevice =
  Modernizr.touch ||
  deviceAgent.match(/(iphone|ipod|ipad)/) ||
  deviceAgent.match(/(android)/) ||
  deviceAgent.match(/(iemobile)/) ||
  deviceAgent.match(/iphone/i) ||
  deviceAgent.match(/ipad/i) ||
  deviceAgent.match(/ipod/i) ||
  deviceAgent.match(/blackberry/i) ||
  deviceAgent.match(/bada/i);

//////////
// The Hover Main Menu
//////////

function hoverMenu() {
  var delay = 200,
    setTimeoutConst;

  $('.navbar-nav > li').hover(function(e) {
    var hasDropdown = $(this).has('.dropdown-toggle').length != 0;

    if (hasDropdown) {
      var height = $(this)
        .find('.dropdown-menu')
        .height();
      var thisHas = $(this);

      clearTimeout(setTimeoutConst);

      setTimeoutConst = setTimeout(function() {
        thisHas.siblings().removeClass('open2');
        thisHas
          .siblings()
          .find('.dropdown-menu:visible')
          .stop()
          .hide();

        thisHas.addClass('open2');

        $('.dropdown-bg')
          .stop()
          .animate({ height: height + 150 }, 150);

        $(thisHas)
          .find('.dropdown-menu')
          .stop()
          .delay(80)
          .fadeIn(120);
      }, delay);
    } else {
      setTimeout(function() {
        $('.navbar-nav li.open2')
          .find('.dropdown-menu')
          .stop()
          .hide();
        $('.navbar-nav li.open2').removeClass('open2');

        $('.dropdown-bg')
          .stop()
          .delay(100)
          .animate({ height: 0 }, 80, 'linear');
      }, delay);
    }
  });

  $('.nav-primary .nav-inner .navbar-nav').mouseleave(function(e) {
    clearTimeout(setTimeoutConst);

    if ($('.dropdown-bg:animated')) {
      $('.dropdown-bg')
        .stop()
        .delay(200)
        .animate({ height: 0 }, 150, 'linear');
    }

    $(this)
      .find('.dropdown-menu')
      .stop()
      .fadeOut(100);
    $('.navbar-nav li.open2').removeClass('open2');
  });
}

// Calling the Menu function
$(function() {
  // commented line breaks the click-dropdown on iPad
  //if (isTouchDevice || $(window).width() < 767) {
  if ($(window).width() < 767) {
  } else {
    hoverMenu();
  }
});

//////////
// The new Product lists with more components
//////////

function productInfo() {
  if (!isTouchDevice) {
    $(window).load(function() {
      var height = $('.productlist-images > div')
        .last()
        .height();
      $('.products-list .productlist-images > div')
        .last()
        .css('height', height);

      // Products in pages
      var height = $('.page-products > div')
        .last()
        .height();
      $('#content .page-products > div')
        .last()
        .css('height', height);
    });

    $('.dropdown-info').mouseenter(function() {
      $(this)
        .parent()
        .css('z-index', 10000);
      $(this).addClass('info');
    });

    $('.dropdown-info').mouseleave(function() {
      $(this).removeClass('info');
      $(this)
        .parent()
        .css('z-index', 'auto');
    });
  }
}

// calling the function
productInfo();

//////////
// Products list - Infinity loader
//////////

$('.btn-loadmore').on('click', function(e) {
  var loadButton = $('.btn-loadmore');
  var Path = $('a.nextpage-link').attr('href');
  var sourceContainer = '.productlist';
  var pagination = '.div-max1170-centered .col-md-8 a.nextpage-link';

  $(this)
    .find('span')
    .hide();
  $(this)
    .find('i')
    .addClass('fa fa-refresh fa-spin');

  $.ajax({
    cache: false,
    url: Path,
    dataType: 'html',
    success: function(htmlSource) {
      // Append
      $('.productlist').append(
        $(sourceContainer, htmlSource)
          .contents()
          .filter('.row')
          .addClass('animated fadeInUp appendedEl')
      );

      if ($(pagination, htmlSource).length > 0) {
        $(pagination).attr('href', $(pagination, htmlSource).attr('href'));
      } else {
        $(pagination)
          .parent('.text-center')
          .html('');
      }

      // Set all new products in same height
      $('.appendedEl .display-name').setAllToMaxHeight();

      // Since iPad freeze DOM while scrolling lazy effect gets very slow for users, remove when under iPad landscape size
      if ($(window).width() <= 1024) {
        $('img.lazy').each(function() {
          $(this).attr('src', $(this).attr('data-original'));
          $(this).removeAttr('data-original');
          $(this).removeClass('lazy');
        });
      }

      // lazyload
      $('.appendedEl .lazy')
        .show()
        .lazyload({
          effect: 'fadeIn'
        });

      // Tooltip
      $('.cart-tooltip').tooltip();

      // Calling the product component function
      productInfo();
    }
  });

  $(document).ajaxComplete(function() {
    $('.btn-loadmore')
      .find('i')
      .removeClass('fa fa-refresh fa-spin');
    $('.btn-loadmore')
      .find('span')
      .show();
  });
});

// Detect IE
function imIE() {
  if (navigator.userAgent.match(/msie|trident/i)) {
    return true;
  }
}

// Detect IE supports 11, edge
// returns version of IE or false, if browser is not Internet Explorer

function detectIE() {
  var ua = window.navigator.userAgent;

  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}

// Cart, Wrap the lists
function wrapLists() {
  $('.cart-filterlistHead').each(function() {
    $(this)
      .nextUntil('.cart-filterlistHead, .tools-buttons')
      .wrapAll('<div class="cart-filterlistRows"></div>');
    $(this)
      .next('.cart-filterlistRows')
      .appendTo(this);
  });
}

wrapLists();

// Cart lists collapse

function toCollapseCartList(me) {
  $(me)
    .parents('.cart-filterlistHead')
    .find('.cart-filterlistRows')
    .toggle();
}

///////
// Add product to favorites
///////
function addToFavorites(linkAddr, e) {
  $(e)
    .find('i')
    .removeClass('fa-heart')
    .addClass('fa-circle-o-notch fa-spin');

  $.ajax({
    cache: false,
    url: linkAddr,
    success: function(data) {
      $(e)
        .find('i')
        .removeClass('fa-circle-o-notch fa-spin')
        .addClass('fa-heart');
    }
  });
}

//////////
// Add items to basket
//////////
function addToBasket(pageId, itemId, produktNumber, domElem) {
  var tempHtml = $(domElem).html();
  var workingElement = $(domElem);

  workingElement.find('i').removeClass('fa-shopping-cart');
  workingElement.html(workingElement.html().replace('Köp', ''));
  workingElement.attr('disabled', true);

  $(document).ajaxComplete(function() {
    workingElement.html(tempHtml);
    workingElement.removeAttr('disabled');
    workingElement.blur();

    // show response from the script.
    $(workingElement)
      .removeAttr('disabled')
      .addClass('animated pulse');
  });

  var quantity = $('#antal_' + produktNumber).val();

  if (typeof quantity == 'undefined') {
    quantity = 1;
  }

  // Update the cart
  updateCart(
    '/default.aspx?id=' +
      pageId +
      '&amp;productid=' +
      itemId +
      '&amp;quantity=' +
      quantity +
      '&amp;cartcmd=add',
    undefined,
    true,
    domElem
  );
}

function updateShipping(url, shippingMethodId, shippingMethodName) {
  $.ajax({
    cache: false,
    scriptCharset: 'utf-8',
    url:
      '/VokesHandler.ashx?ShippingMethodId=' +
      shippingMethodId +
      '&&ShippingMethodName=' +
      shippingMethodName,
    success: function(data) {
      updateCart(url);
    },
    error: function() {}
  });
}

function updatePayment(url, paymentMethodId, paymentMethodName) {
  $.ajax({
    cache: false,
    scriptCharset: 'utf-8',
    url:
      '/VokesHandler.ashx?PaymentMethodId=' +
      paymentMethodId +
      '&&PaymentMethodName=' +
      paymentMethodName,
    success: function(data) {
      updateCart(url);
    },
    error: function() {}
  });
}

/////////
// Update cart/product (also delete  - if no formID is present)
/////////
function updateCart(url, formid, asynced, src) {
  //Update punchout row
  var inputQuantityElement = $(src)
    .parent()
    .find('input[type="number"]');
  var inputQuantityId = inputQuantityElement.attr('id');
  var rowQuantity = inputQuantityElement.val();
  $('#punchoutOrderLine_' + inputQuantityId)
    .find('#Quantity_' + inputQuantityId)
    .val(rowQuantity);

  //Trim form depending on screen size
  $(document).ajaxComplete(function() {
    // show response from the script.
    $(src)
      .removeAttr('disabled')
      .addClass('animated pulse');
    $(src)
      .find('.fa')
      .removeClass('fa-refresh fa-spin');

    ResponsiveChanges();
    $('.cart-tooltip').tooltip();
  });

  $('input[name="AXShipCompCategory"]').addClass('notme');
  $('input[name="AXDeliveryWithTaillift"]').addClass('notme');

  //If formid is undefined, we're deleting an order line
  var formData = '';
  if (formid != undefined) {
    //Serialize form data if we're updating the quantity
    formData = $('<form></form>');
    formData.append($('#' + formid + ' input:not(.notme)'));
    formData = formData.serialize();
  }

  if (asynced == undefined) asynced = true;

  $(src)
    .find('.fa')
    .addClass('fa-refresh fa-spin');

  $.ajax({
    cache: false,
    type: 'POST',
    url: url,
    dataType: 'html',

    async: asynced,
    data: formData,
    success: function(data) {
      if ($('#basket-btn').length != 0) {
        $('#basket-btn').html($('#basket-btn', data).html());

        if (
          $('#basket-btn i')
            .attr('class')
            .indexOf('Its-empty') == -1
        ) {
          $('.basket').html($('.basket', data).html());
          $('#basket-btn').removeAttr('disabled');
        } else {
          $('#basket-btn').attr('disabled', 'disabled');
          $('.basket').removeClass('in');
        }
      }

      // timeout * doesn't work

      $('.cart-data').html($('.cart-data', data).html());

      $('.large-basket').html($('.large-basket', data).html());

      $('.b-close').click(function() {
        $('.basket').hide();
      });

      // putt the correct emptycard url
      emptyCard_url();
      // Cart wrap the lists
      wrapLists();
    }
  });
}

/////////
// Delete an order line
// This function adds an animation before actually calling the function that deletes the line
/////////
function deleteOrderLine(url, obj, orderLineIdObj) {
  var elem = $(obj);

  //Delete punchout row
  var inputElement = $(orderLineIdObj);
  var orderLineId = inputElement.attr('id');
  $('#punchoutOrderLine_' + orderLineId).remove();

  //Parents parent is the table row
  // Detecting IE
  if (imIE()) {
    elem
      .parent()
      .parent()
      .hide();
  }

  //Parents parent is the table row
  elem
    .parent()
    .parent()
    .addClass('animated bounceOut');
  updateCart(url);

  // If its last order row reload the page
  if (
    elem
      .parent()
      .parent()
      .is(':last-child')
  ) {
    updateCart(url);

    setTimeout(function() {
      location.reload(true);
    }, 100);
  }
}

// Empty card button link
function emptyCard_url() {
  var url = window.location.href;
  var empty = 'cartcmd=emptycart';

  if (url.indexOf('?') > -1) {
    $('.empty-cart.btn').attr('href', url + '&' + empty);
  } else {
    $('.empty-cart.btn').attr('href', url + '?' + empty);
  }
}
emptyCard_url();

/////////
// Delete Fav line
// This function adds an animation before actually calling the function that deletes the line
/////////
function deleteFavLine(url, obj) {
  var elem = $(obj);

  //Parents parent is the table row
  elem
    .parent()
    .parent()
    .addClass('animated fadeOutLeft')
    .remove();
  updateCart(url);
}

// Update order list object

function UpdateOrderLineObject(element, orderId, orderLineId, url) {
  var ListObject = $('#txt-' + orderId + '-' + orderLineId).val();
  var Place = $('#txtPlace-' + orderId + '-' + orderLineId).val();
  var Installno = $('#txtInstallNo-' + orderId + '-' + orderLineId).val();

  $.ajax({
    cache: false,
    scriptCharset: 'utf-8',
    url:
      '/VokesHandler.ashx?Class=OrderLine&&OrderID=' +
      orderId +
      '&&OrderLineID=' +
      orderLineId +
      '&&ListObject=' +
      encodeURIComponent(ListObject) +
      '&&Place=' +
      encodeURIComponent(Place) +
      '&&InstallNo=' +
      encodeURIComponent(Installno),
    success: function(data) {
      $(element).css('border-color', '#008244');
    },
    error: function() {
      $(element).css('border-color', 'red');
    }
  });
}

/*
 * Check if customers are forced to use credit card
 */
function forceCreditCard(forceIt, content, targetElementId) {
  if (forceIt == 'True') {
    $('#' + targetElementId).html(
      '<input type="hidden" name="EcomCartPaymethodID" value="PAY2">Kreditkort - Visa eller Mastercard'
    );
  } else {
    $('#' + targetElementId).html(
      content + '<input type="hidden" name="EcomCartPaymethodID" value="PAY3">'
    );
  }
}

/*
 * Check if the user name is to long
 */
function checkUserNameLength(username, containerId, length) {
  var acceptedName = '';
  if (username.length > length) {
    acceptedName = username.substr(0, length - 3) + '...';
  } else {
    acceptedName = username;
  }
  $('#' + containerId).html(acceptedName);
}

/*
 *   Check VAT setting - or save it onchange
 */

function setCookie(c_name, value, exdays, path) {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + exdays);

  value = String(value);

  document.cookie =
    encodeURIComponent(c_name) +
    '=' +
    encodeURIComponent(value) +
    '; expires=' +
    exdate.toUTCString() +
    '; path=' +
    path;
}

function getCookie(c_name) {
  var i,
    x,
    y,
    ARRcookies = document.cookie.split(';');
  for (i = 0; i < ARRcookies.length; i++) {
    x = ARRcookies[i].substr(0, ARRcookies[i].indexOf('='));
    y = ARRcookies[i].substr(ARRcookies[i].indexOf('=') + 1);
    x = x.replace(/^\s+|\s+$/g, '');
    if (x == c_name) {
      return unescape(y);
    }
  }
}

$(document).ready(function() {
  $("input:radio[name='momsRadios']").change(function() {
    var expDate = new Date();
    expDate = expDate.setMonth(expDate.getMonth() + 12);
    setCookie('vatsetting', this.value, 30, '/');
    window.location.reload();
  });

  if (getCookie('vatsetting')) {
    $(
      "input:radio[name='momsRadios'][value='" + $.cookie('vatsetting') + "']"
    ).attr('checked', true);
  }

  // Make sure only one user group can be selected
  $('#group-selector input:radio').click(function() {
    $('#group-selector input:radio')
      .not($(this))
      .removeAttr('checked');
  });
});

var errorItemName =
  '<li><a><i class="fa fa-info-circle"></i>Namn : du måste fylla i ditt namn</a></li>';
var errorItemAddr =
  '<li><a><i class="fa fa-info-circle"></i>Adress : du måste fylla i en adress</a></li>';
var errorItemPhon =
  '<li><a><i class="fa fa-info-circle"></i>Telefon : du måste fylla i telefonnummer</a></li>';
var errorItemZipc =
  '<li><a><i class="fa fa-info-circle"></i>Postnummer : du måste fylla i postnummer</a></li>';
var errorItemCity =
  '<li><a><i class="fa fa-info-circle"></i>Ort : du måste fylla i ort</a></li>';
//var errorItemGrou = '<li><a><i class="fa fa-info-circle"></i>Kundtyp : Du måste välja en grupp</a></li>';

$(document).ready(function() {
  $('#carousel-productimages').swiperight(function() {
    $(this).carousel('prev');
  });
  $('#carousel-productimages').swipeleft(function() {
    $(this).carousel('next');
  });
  $('#modalCarousel').swiperight(function() {
    $(this).carousel('prev');
  });
  $('#modalCarousel').swipeleft(function() {
    $(this).carousel('next');
  });
});

var inputs = new Array();
inputs.push($("input[name='UserManagement_Form_Name']"));
inputs.push($("input[name='UserManagement_Form_Address']"));
inputs.push($("input[name='UserManagement_Form_Phone']"));
inputs.push($("input[name='UserManagement_Form_Zip']"));
inputs.push($("input[name='UserManagement_Form_City']"));

var htmlErrorBox =
  '<div class="bg-danger cart-error"><h2>Fel i formuläret</h2><p>Vänligen rätta följande: </p><ul></ul></div>';

/*window.addEventListener('load', function () {
    var form = document.querySelector('form[name=UserManagementEditForm]');
    if (form) {
        form.addEventListener('submit', function (event) {
			
			var hasErrors = false;
			
            if ($("#group-selector input:checked").length == 0) {
                if ($('.bg-danger').length == 0) {
                    $("form[name='UserManagementEditForm']").prepend(htmlErrorBox);
                }
                $('.bg-danger ul').append(errorItemGrou);
				hasErrors = true;
            }

            $.each(inputs, function (index, field) {				
                if (field.is('input[type="text"]') && !field.val()) {
                    if ($('.bg-danger').length == 0) {
                        $("form[name='UserManagementEditForm']").prepend(htmlErrorBox);
                    }
                    switch (field.attr('name')) {
                        case 'UserManagement_Form_Name':
                            $('.bg-danger ul').append(errorItemName);
                            break;
                        case 'UserManagement_Form_Address':
                            $('.bg-danger ul').append(errorItemAddr);
                            break;
                        case 'UserManagement_Form_Phone':
                            $('.bg-danger ul').append(errorItemPhon);
                            break;
                        case 'UserManagement_Form_Zip':
                            $('.bg-danger ul').append(errorItemZipc);
                            break;
                        case 'UserManagement_Form_City':
                            $('.bg-danger ul').append(errorItemCity);
                            break;
                    }

                    hasErrors = true;
                }
            });

            if (hasErrors) {
                event.preventDefault();
            }


        });
    }
});*/

//////////////
// userSwitch
////////////

function mapUsers() {
  // $.getJSON("/userValidate.ashx?type=get", function (data) {
  //     $.each(data, function (index, item) {
  //         $('#DWExtranetSecondaryUserSelector').append(
  //                 $('<option value=' + item.id + ' data-tokens></option>').html(item.CustomerNumber + " / " + item.name + " → " + item.email).attr("data-tokens", item.name + " " + item.CustomerNumber  + " " + item.email)
  //             );
  //     });
  // })

  $.ajax({
    dataType: 'json',
    url: '/userValidate.ashx?type=get',
    headers: {
      'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache'
    },
    success: function(data) {
      $.each(data, function(index, item) {
        $('#DWExtranetSecondaryUserSelector').append(
          $('<option value=' + item.id + ' data-tokens></option>')
            .html(item.CustomerNumber + ' / ' + item.name + ' → ' + item.email)
            .attr(
              'data-tokens',
              item.name + ' ' + item.CustomerNumber + ' ' + item.email
            )
        );
      });
    }
  });
}

function SwitchUser() {
  var userID = $('#DWExtranetSecondaryUserSelector option:selected').val();
  // PageID/Pageview needed for security.ExtranetLogin to work, when globalsetting IncludeShopIdInExtranetLogIn=true
  var pageID = $('#DWExtranetSecondaryUserSelectorPageID').text();
  var url = '/userValidate.ashx?type=set&id=' + userID + '&pageID=' + pageID;

  $.ajax({
    cache: false,
    url: url,
    headers: {
      'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache'
    }
  }).done(function() {
    var site = window.location.href;

    if (site.indexOf('kassa.aspx') > -1) {
      document.location.href = '/';
    } else {
      //window.location.reload(true);
      //IE fix to avoid varning message.
      window.location.href = site;
    }
  });
}

function releaseUser() {
  var pageID = $('#DWExtranetSecondaryUserSelectorPageID').text();
  // PageID/Pageview needed for security.ExtranetLogin to work, when globalsetting IncludeShopIdInExtranetLogIn=true
  var url = '/userValidate.ashx?type=release&pageID=' + pageID;

  $.ajax({
    cache: false,
    url: url,
    headers: {
      'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache'
    }
  }).done(function() {
    var site = window.location.href;

    if (site.indexOf('kassa.aspx') > -1) {
      document.location.href = '/';
    } else {
      //window.location.reload(true);
      //IE fix to avoid varning message.
      window.location.href = site;
    }
  });
}

//addresslist.
var addresslist = [];
var addresslist2 = [];
var listValue = -1;

function getAddressesFromAx() {
  var data2 = $('#adressData').attr('dataArray');
  var dataArray = new Array();
  dataArray = data2.split(',');

  // Always populate delivery adress selecbox with adresses from AX
  //$.makeArray(data2);

  $.ajax({
    cache: false,
    type: 'GET',
    url: '/Airfiltertool/AxAddress.ashx',
    contentType: 'json',
    headers: {
      'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache'
    },
    success: function(data) {
      $.each(data, function(index, item) {
        var street = item.Street;

        item.Street = item.Street.replace('↵', '<br />');
        addresslist.push(item);
        var textToDisplay =
          item.Name + ', ' + street + ' ' + item.Zip + ' ' + item.City;
        $('#addressSelector').append(
          $('<option value=' + item.id + '></option>').html(textToDisplay)
        );

        listValue++;
      });

      $.each(dataArray, function(index, item) {
          listValue++;
        if (item.length === 0) return;
        var adressString = item.split('#');
        var streetClean;
        var street = adressString[1];

          if (street.length !== 0 ) {

             streetClean = street.replace('↵', '<br />');
          }
 

        var textToDisplay =
          adressString[0] + streetClean + adressString[2] + adressString[3];
        $('#addressSelector').append(
          $('<option value=' + listValue + '></option>').html(textToDisplay)
        );

        addresslist.push({
          Name: adressString[0],
          Street: streetClean,
          City: adressString[2],
          Zip: adressString[3]
        });
      });
      $('#addressSelector').selectpicker();
    }
  });

  // $.getJSON("/Airfiltertool/AxAddress.ashx", function(data) {
  //        $.each(data, function(index, item) {
  // 		var street=item.Street;
  // 		item.Street=item.Street.replace('↵', '<br />');
  //            addresslist.push(item);
  // 		var textToDisplay=item.Name+", "+street+" "+item.Zip+" "+item.City;
  //            $('#addressSelector').append(
  //                $('<option value=' + item.id + '></option>').html(textToDisplay)
  //            );
  //        });
  //    });

  // If its only one list in the basket this var and adress vars are definened in page source
  // In case of one list, populate delivery adress fields with adress data from that list
  if (typeof oneList != 'undefined' && oneList === true) {
    // If something is not already written in the delivery adress fields
    // For example changes values after a page reload, dont overwrite userdefined values again
    var test = $('#EcomOrderDeliveryCompany').val();
    if (test == '') {
      $('#EcomOrderDeliveryCompany').val(aName);
      $('#EcomOrderDeliveryCity').val(aCity);
      $('#EcomOrderDeliveryZip').val(aZip);
      $('#EcomOrderDeliveryAddress').val(aStreet);
    }
  }
}

function setAddress() {
  var index = $('#addressSelector').val();
  if (index == -1) {
    $('#EcomOrderDeliveryCompany').val('');
    $('#EcomOrderDeliveryCity').val('');
    $('#EcomOrderDeliveryZip').val('');
    $('#EcomOrderDeliveryAddress').val('');
  } else {
    $('#EcomOrderDeliveryCompany').val(addresslist[index].Name);
    $('#EcomOrderDeliveryCity').val(addresslist[index].City);
    $('#EcomOrderDeliveryZip').val(addresslist[index].Zip);
    $('#EcomOrderDeliveryAddress').val(addresslist[index].Street);
    //$('#AddressCountry').val(addresslist[index].Country);
  }
}

// works on ie 10 +
function resetUrlParameters() {
  var url = window.location.pathname;

  var newurl = url.split('?')[0];
  window.history.pushState('resetUrl', 'products', newurl);
}

var PlaceItemID = '';
var PlaceRedirectUrl = '';

function saveToRow() {
  var GroupID = getCookie('GroupID');
  var RowID = getCookie('RowID');
  var ListID = getCookie('ListID');

  $.ajax({
    url:
      location.protocol +
      '//' +
      location.host +
      '/Airfiltertool/ListHandler.ashx?action=addFromEcom&ID=' +
      PlaceItemID +
      '&ListID=' +
      GroupID +
      '&RowID=' +
      RowID
  });
  window.location =
    location.protocol + '//' + location.host + '/' + PlaceRedirectUrl;
}

function saveAsFavorite(url) {
  // start spinner ?

  $.ajax({
    cache: false,
    type: 'GET',
    url: url,

    processData: false,
    async: true,
    success: function(data) {
      // Stop spinner ?
    }
  });
}

function favHandeler(ItemID, url, redirectUrl) {
  if (getCookie('RowID')) {
    PlaceItemID = ItemID;
    PlaceRedirectUrl = redirectUrl;
    $('#AddToListModal').modal('show');
  } else {
    saveAsFavorite(url);
  }
}

/////////
// 404 Page
/////////

$('.y-404 .row').fadeIn(100);
$('.y-404-face i').fadeIn(600, function() {
  $('.y-404-content').fadeIn('fast', function() {
    $('.y-404-content a').fadeIn();
  });
});

/////////
// PDF print - send html to print to PDFhandler.ashx
/////////

function printPDF(
  visibleElementID,
  printPDFCssFilePath,
  x,
  Criteria,
  htmlToDisplay,
  headerdetails
) {
  var printSource = '';
  var htmlBodyContents = '';
  if (Criteria == 'FilterLists') htmlBodyContents = htmlToDisplay;
  else htmlBodyContents = $('#orderlines')[0].outerHTML;

  printSource += '<html>';
  printSource += $('head')[0].outerHTML;
  printSource += '<body>';
  printSource += htmlBodyContents;
  printSource += '</body>';
  printSource += '</html>';
  printSource = printSource.replace(
    '<!-- INSERT-PDF-PRINT-CSS-->',
    '<link rel="stylesheet" href="' + printPDFCssFilePath + '" />'
  );

  $(x)
    .find('span')
    .hide();
  $(x)
    .find('i.fa-spin')
    .css('display', 'inline-block');

  $.ajax({
    cache: false,
    type: 'POST',
    url:
      location.protocol +
      '//' +
      location.host +
      '/Airfiltertool/PDFhandler.ashx?visibleElementID=' +
      visibleElementID +
      '&Criteria=' +
      Criteria +
      '&HeaderDetails=' +
      JSON.stringify(headerdetails),
    contentType: 'html',
    processData: false,
    data: printSource,
    async: true,
    success: function(data) {
      // Redirect to the generated PDF on the URL that the server responded
      $('.pdf-modal iframe').attr(
        'src',
        location.protocol +
          '//' +
          location.host +
          '/Airfiltertool/PDFhandler.ashx?Criteria=Download&FileName=' +
          data,
        '_blank'
      );
      $('.pdf-modal iframe').height($(window).height() * 0.9);
      $('.pdf-modal .modal-lg').css(
        'margin-top',
        $(window).height() - (0.95 % +'!important')
      );

      setTimeout(function() {
        $('.pdf-modal').modal();
        $(x)
          .find('i.fa-spin')
          .hide();
        $(x)
          .find('span')
          .show();
      }, 1000);
    }
  });
}

// ProductList Filter init
$('.btn-average').tooltip();

// Collapse
$('.panel-heading.toCollapse').click(function() {
  $(this)
    .next('.collapse-me')
    .toggle();
});

// standard from  w3c

function getCookie(cname) {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1);
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return '';
}

// valdiate helper

function updateOrdetPhone() {
  $('#AXTelephone').val($('#Hold').val());
}

function orderPhoneToggle() {
  if ($('#Hold').prop('disabled') == true) {
    $('#Hold').prop('disabled', false);
    $('#AXTelephone').val($('#Hold').val());
  } else {
    $('#Hold').prop('disabled', true);
    $('#AXTelephone').val('noNumber');
  }
}

// Date Picker - checkout page
$('.cart-paymentInfo .date-picker').datepicker({
  language: 'sv',
  format: 'yyyy-mm-dd',
  todayBtn: true,
  calendarWeeks: true,
  autoclose: true,
  todayHighlight: true
});

// select picker
if (detectIE() == false) {
  $(window).load(function() {
    setTimeout(function() {
      $('.selectpickerx').selectpicker();
    }, 1000);
  });
} else {
  $(window).load(function() {
    setTimeout(function() {
      $('.selectpickerx').selectpicker();
    }, 1000);
  });
}

$('.invoiceCountry').on('changed.bs.select', function(
  event,
  clickedIndex,
  newValue,
  oldValue
) {
  var defaultText = $('.invoiceCountry')
    .find('.defaultValue')
    .text();

  var text = $('.invoiceCountry')
    .parent()
    .find('button span')
    .text();

  if (text != defaultText) {
    $('.invoiceCountry')
      .next('input')
      .val(text);
  } else {
    $('.invoiceCountry')
      .next('input')
      .val('');
  }
});

$('.deliveryCountry').on('changed.bs.select', function(
  event,
  clickedIndex,
  newValue,
  oldValue
) {
  var defaultText = $('.deliveryCountry')
    .find('.defaultValue')
    .text();

  var text = $('.deliveryCountry')
    .parent()
    .find('button span')
    .text();
  if (text != defaultText) {
    $('.deliveryCountry')
      .next('input')
      .val(text);
  } else {
    $('.deliveryCountry')
      .next('input')
      .val('');
  }
});

// Ax Message
$('.ax-message').click(function() {
  var top = $(this).position();
  if (top.top == 0) {
    $(this).css('top', -46);
  } else {
    $(this).css('top', 0);
  }
});
