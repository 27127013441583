
/////////
// Filter lists
// 
// Resources: 
// http://angular-ui.github.io/bootstrap/#/modal
// http://angular-ui.github.io/bootstrap/#/tabs
// http://vitalets.github.io/angular-xeditable/
/////////

var isSave = true;
var Root = '/AirFilterTool/ListHandler.ashx';
var RootProducts = '/AirFilterTool/ProductSearch.ashx';
var app = angular.module("FilterLists", ["xeditable", 'ngAnimate', "ui.bootstrap", "ngCookies", "ui.sortable", ]);

function getParameter(name) {
    if (name = (new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)')).exec(location.search))
        return decodeURIComponent(name[1]);
}


// Disabling IE ajax request caching

if (detectIE() === 11) {

  app.config(['$httpProvider', function($httpProvider) {
      //initialize get if not there
      if (!$httpProvider.defaults.headers.get) {
          $httpProvider.defaults.headers.get = {};    
      }    

      // Answer edited to include suggestions from comments
      // because previous version of code introduced browser-related errors

      // Disable IE ajax request caching
      $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
      // extra
      $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
      $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';
  }]);
    
}




app.run(function (editableOptions) {
    editableOptions.theme = 'bs3';
});

//convert string to int when not recognized as numbers. 
app.directive('input', [function () {
    return {
        restrict: 'E',
        require: '?ngModel',
        link: function (scope, element, attrs, ngModel) {
            if (
                   'undefined' !== typeof attrs.type
                && 'number' === attrs.type
                && ngModel
            ) {
                ngModel.$formatters.push(function (modelValue) {
                    return Number(modelValue);
                });

                ngModel.$parsers.push(function (viewValue) {
                    return Number(viewValue);
                });
            }
        }
    }
}]);

// Needed to bind static select values to the model value
app.directive('convertToNumber', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function (val) {
                return parseInt(val, 10);
            });
            ngModel.$formatters.push(function (val) {
                return '' + val;
            });
        }
    };
});

// Detect when finish render repeating
app.directive('onFinishRender', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            if (scope.$last === true) {
                $timeout(function () {
                    scope.$emit(attr.onFinishRender);
                });
            }
        }
    }
});


// List Listview Controller (Read)
app.controller('PropertyItems', function ($scope, $http, $modal, $log, $cookies) {


    $scope.OpenRow = [];
    //catch variables and clear cookies .
    if ($cookies.get("RowID") != undefined) {

        //openListPath(getParameter("List"), getParameter("UnfoldGroup"), getParameter("Row"));

        //modal values 
        $scope.OpenRow.ListID = $cookies.get("ListID");
        $scope.OpenRow.GroupID = $cookies.get("GroupID");
        $scope.OpenRow.RowID = $cookies.get("RowID");

        $cookies.remove("ListID");
        $cookies.remove("GroupID");
        $cookies.remove("RowID");


        alert("En produkt har lagts till på den senaste raden");



    }


    // Init the properties
    $scope.properties = [];
    $scope.serchFilterItems = [];
    $scope.selectProperties = [];

    

    // Loading spinner
    $scope.loading = true;

    $scope.addresses = [];
    $.getJSON("/Airfiltertool/AxAddress.ashx", function (data) {
        $.each(data, function (index, item) {
            $scope.addresses.push(item);
        });
    });



    

    $http.get(Root + "?action=ListsRead").success(function (data) {

        $scope.properties = data;
        $scope.serchFilterItems = data;
        $scope.selectProperties = data;
        
    }).finally(function () {
        // Hide loading spinner
        $scope.loading = false;
        InitAll();

        if (scope.OpenRow.RowID) {
            //$scope.openListPath();
        }
    });


    // Check if Much Lists - To avoid the Content rendering
    $scope.muchLists = true;

 



    $scope.BasketID = null;

    $scope.BasketIDs = function () {
        var IDList = [];
        $scope.BasketID = $(".orderlinetable.row.row-item").each(function () {
            IDList.push($(this).attr('id'));
        });
        $scope.BasketID = IDList;
    };

    $scope.checkRowInBasketSubGroup = function (group) {
        $scope.BasketIDs();
        var breakout = false;
        if (group != null && group != "") {
            if (group.filters != null && group.filters != "") {
                $.each(group.filters, function (index, value) {
                    if ($.inArray('basketRow-' + value.ID, $scope.BasketID) > -1) {
                        breakout = true;
                        return true;
                    }
                });
            }
        }
        return breakout;
    }

    $scope.checkIfGroupHasItemsOnHold = function (group) {
        var breakout = false;
        $.each(group.filters, function (index, filter) {
            if (filter.OnHold) {
                breakout = true;
                return true;
            }
        });
        return breakout;
    }


    $scope.checkIfPropertyHasItemsOnHold = function (property) {
        var breakout = false;

        $.each(property.groups, function (index, group) {
            $.each(group.filters, function ( index, filter) {
                if (filter.OnHold) {
                    breakout = true;
                    return true;
                }
            });
        })
     
        return breakout;
    }


    $scope.checkRowInBasketParentGroup = function (property) {      
        $scope.BasketIDs();
        var breakout = false;
        if (property.groups != null && property.groups != "") {
            $.each(property.groups, function (groupindex, groupvalue) {
                if (groupvalue.filters != null && groupvalue.filters != "") {
                    $.each(groupvalue.filters, function (index, value) {
                        if ($.inArray('basketRow-' + value.ID, $scope.BasketID) > -1) {
                            breakout = true;
                            return true;
                        }
                    });
                }
            });
        }
        return breakout;
    }


    $scope.checkRowInBasket = function (filter) {

        if (angular.element('#basketRow-' + filter.ID).length) {
            return true;
        }
        return false;
    }

    

    // Lists Search ..
    $scope.FilterSearchModel = "";
    $scope.filterSearch = function (element) {

        var filterSearchItems = $scope.serchFilterItems;
        var finalFilterListItems = [];
        var searchPattern = $("#txtFilterSearch").val().toLowerCase().trim();
        var defaultSelectValue = $('.filters-select .select-items li:first-child').data('value');
        
        // Set default value to select items
        $('.filters-select .select-head span').text(defaultSelectValue)

        if (searchPattern == null || searchPattern == "" || element == 'clear') {
            $scope.selectProperties = filterSearchItems;
            $("#txtFilterSearch").val("");

            // To avoid the Content rendering
            $scope.muchLists = true;

        } else if (searchPattern.length >= 3) {

            // To avoid the Content rendering
            $scope.muchLists = true;
            

            $.each(filterSearchItems, function (index, value) {

                var flag = false;
                var MapName = value.MapName == null ? "" : value.MapName.trim().toLowerCase();
                var MapNumber = value.MapNumber == null ? "" : value.MapNumber.trim().toLowerCase();
                var DeliveryCity = value.DeliveryCity == null ? "" : value.DeliveryCity.trim().toLowerCase();
                var DeliveryName = value.DeliveryName == null ? "" : value.DeliveryName.trim().toLowerCase();
                var DeliveryStreet = value.DeliveryStreet == null ? "" : value.DeliveryStreet.trim().toLowerCase();
                var DeliveryZipCode = value.DeliveryZipCode == null ? "" : value.DeliveryZipCode.trim().toLowerCase();
                var Contact = value.Contact == null ? "" : value.Contact.trim().toLowerCase();
                var ContactID = value.ContactID == null ? "" : value.ContactID.trim().toLowerCase();
                var CustomerNumber = value.CustomerNumber == null ? "" : value.CustomerNumber.trim().toLowerCase();

                if ((MapName.indexOf(searchPattern) > -1) || (MapNumber.indexOf(searchPattern) > -1) || (DeliveryCity.indexOf(searchPattern) > -1) || (DeliveryName.indexOf(searchPattern) > -1) || (DeliveryStreet.indexOf(searchPattern) > -1) || (DeliveryZipCode.indexOf(searchPattern) > -1) || (Contact.indexOf(searchPattern) > -1) || (ContactID.indexOf(searchPattern) > -1) || (CustomerNumber.indexOf(searchPattern) > -1))
                    flag = true;

                if (flag != true) {

                    if (value.groups != null) {
                        $.each(value.groups, function (index, groupItem) {
                            if (groupItem.filters != null) {
                                $.each(groupItem.filters, function (index, filtersItem) {
                                    var InstallNo = filtersItem.InstallNo == null ? "" : filtersItem.InstallNo.trim().toLowerCase();
                                    var Object = filtersItem.Object == null ? "" : filtersItem.Object.trim().toLowerCase();
                                    var Place = filtersItem.Place == null ? "" : filtersItem.Place.trim().toLowerCase();
                                    var Itemnumber = filtersItem.Itemnumber == null ? "" : filtersItem.Itemnumber.trim().toLowerCase();
                                    var Name = filtersItem.Name == null ? "" : filtersItem.Name.trim().toLowerCase();
                                    if ((InstallNo.indexOf(searchPattern) > -1) || (Object.indexOf(searchPattern) > -1) || (Place.indexOf(searchPattern) > -1) || (Itemnumber.indexOf(searchPattern) > -1) || (Name.indexOf(searchPattern) > -1)) {
                                        flag = true;
                                    }
                                });
                            }
                        });
                    }

                }

                if (flag == true) {


                  finalFilterListItems.push(this);

                } 
            });


            $scope.selectProperties = finalFilterListItems;
        }
    }



    // Lists Select box
    
    $scope.selectedProperties = [];
    $scope.selectIsActive = false;


    $scope.showFilterSelect = function(el) {
        $(el.currentTarget).parent().toggleClass('open');
    }

    // when click the item in select box ..
    $scope.ListSelectClick = function (el, id) {
        var selectHead = $('.filters-select .select-head span');
        
        // Detect Buy button
        if ($(el.target).is('.filters-select i') || $(el.target).is('.filters-select a')) {
            el.preventDefault();
        } else {
            $(el.currentTarget).parents('.filters-select').toggleClass('open');
            selectHead.text($(el.currentTarget).data('value'));
            
            if (id === '') {
                $scope.selectIsActive = false;
                $scope.selectedProperties = [];
                $scope.muchLists = true;
            } else {
                $scope.selectIsActive = true;
                // filter the selected item
                $scope.selectedProperties = $scope.properties.filter(function (item) { 
                    if (item.ID.includes(id)) {
                         return item.ID
                    }
                    return null;
                });    
                $scope.muchLists = false;
            }
        }

        
    }
    



    // Add to cart, row
    $scope.addProduct = function (index, groupIndex, listIndex, e) {
        var listID = $scope.properties[listIndex].ID;
        var groupID = $scope.properties[listIndex].groups[groupIndex].ID;
        var filter = $scope.properties[listIndex].groups[groupIndex].filters[index];
  
        $('#list_' + listID + ' .list').attr('disabled', 'disabled');
        $('#group_' + groupID+ ' .group').attr('disabled', 'disabled');

        $(e.currentTarget).attr('disabled', 'disabled');
        $(e.currentTarget).find('.fa').removeClass('fa-shopping-cart').addClass('fa-refresh fa-spin');

        if (filter.Place == null) { filter.Place = ""; }
        if (filter.InstallNo == null) { filter.InstallNo = ""; }

        $.ajax({
            cache: false,
            type: "POST",
            url: location.protocol + "//" + location.host + location.pathname +
                "?productid=" + filter.ProductID +
                "&quantity=" + filter.Quantity +
                "&cartcmd=add" +
                "&masterId=" + listID +
                "&SublistId=" + groupID +
                "&rowId=" + filter.ID +
                "&ListObject=" +encodeURIComponent( filter.Object )+
                "&placement=" + encodeURIComponent(filter.Place) +
                "&subMapName=" + encodeURIComponent(filter.Object) +
                "&InstallNo=" + encodeURIComponent(filter.InstallNo)
            ,
            dataType: "html",
            async: true,
            success: function (data) {
                if ($('#basket-btn').length != 0) {
                    $("#basket-btn").html($("#basket-btn", data).html());

                    if ($("#basket-btn i").attr('class').indexOf('Its-empty') == -1) {
                        $(".basket").html($(".basket", data).html());
                        $("#basket-btn").removeAttr('disabled');
                    } else {
                        $("#basket-btn").attr('disabled', 'disabled');
                        $(".basket").removeClass('in');
                    }
                }

                // Reload the page on IE 11
                if (detectIE() === 11) {
                    // window.location.reload();
                }

                
                $(".cart-data").html($(".cart-data", data).html());
                $(".large-basket").html($(".large-basket", data).html());

                $('.b-close').click(function () {
                    $(".basket").hide();
                });

                // $(e.currentTarget).removeAttr('disabled');
                $(e.currentTarget).find('.fa').removeClass('fa-refresh fa-spin').addClass('fa-check');
                // putt the correct emptycard url
                emptyCard_url();

                // Cart wrap the lists 
                wrapLists();                
            }
        });
    };



    // Add to cart, group
    $scope.addgroupToCart = function (index, parentIndex, e) {

        var items = $scope.properties[parentIndex].groups[index].ID;
        $(e.currentTarget).attr('disabled', 'disabled');
        $(e.currentTarget).find('.fa').removeClass('fa-shopping-cart').addClass('fa-refresh fa-spin');

        $http.post(Root + "?action=AddSubToCart&id=" + items).
                   then(function (response) {
                       $.ajax({
                           cache: false,
                           type: "POST",
                           url: location.protocol + "//" + location.host + location.pathname,  // "/default.aspx"
                           async: false,
                           success: function (data) {

                               if ($('#basket-btn').length != 0) {
                                   $("#basket-btn").html($("#basket-btn", data).html());

                                   if ($("#basket-btn i").attr('class').indexOf('Its-empty') == -1) {
                                       $(".basket").html($(".basket", data).html());
                                       $("#basket-btn").removeAttr('disabled');
                                   } else {
                                       $("#basket-btn").attr('disabled', 'disabled');
                                       $(".basket").removeClass('in');
                                   }
                               }
                              
                              // Reload the page on IE 11
                              if (detectIE() === 11) {
                                  // window.location.reload();
                              }

                               $(".cart-data").html($(".cart-data", data).html());
                               $(".large-basket").html($(".large-basket", data).html());

                               $('.b-close').click(function () {
                                   $(".basket").hide();
                               });

                               $(e.currentTarget).removeAttr('disabled');
                               $(e.currentTarget).find('.fa').removeClass('fa-refresh fa-spin').addClass('fa-shopping-cart');

                               // putt the correct emptycard url
                               emptyCard_url();

                               // Cart wrap the lists 
                               wrapLists();

                           }
                       });
                   }, function (response) {
                       // Error
                   });
    }



    // Add to cart, list
    $scope.addListToCart = function (index, e) {
        var buildList = $scope.properties[index].ID;

        $(e.currentTarget).attr('disabled', 'disabled');
        $(e.currentTarget).find('.fa').removeClass('fa-shopping-cart').addClass('fa-refresh fa-spin');

        $http.post(Root + "?action=AddMasterToCart&id=" + buildList).
                   then(function (response) {
                       $.ajax({
                           cache: false,
                           type: "POST",
                           url: location.protocol + "//" + location.host + location.pathname,  // "/default.aspx"
                           async: false,
                           success: function (data) {

                               if ($('#basket-btn').length != 0) {
                                   $("#basket-btn").html($("#basket-btn", data).html());

                                   if ($("#basket-btn i").attr('class').indexOf('Its-empty') == -1) {
                                       $(".basket").html($(".basket", data).html());
                                       $("#basket-btn").removeAttr('disabled');
                                   } else {
                                       $("#basket-btn").attr('disabled', 'disabled');
                                       $(".basket").removeClass('in');
                                   }
                               }
                              // Reload the page on IE 11
                              if (detectIE() === 11) {
                                  // window.location.reload();
                              }
                               $(".cart-data").html($(".cart-data", data).html());
                               $(".large-basket").html($(".large-basket", data).html());

                               $('.b-close').click(function () {
                                   $(".basket").hide();
                               });

                               $(e.currentTarget).removeAttr('disabled');
                               $(e.currentTarget).find('.fa').removeClass('fa-refresh fa-spin').addClass('fa-shopping-cart');

                               // putt the correct emptycard url
                               emptyCard_url();

                               // Cart wrap the lists 
                               wrapLists();


                           }
                       });
                   }, function (response) {
                       // Error
                   });

    };



    ////// Sorting (Drag n drop) //////


    // List Sorting
    $scope.PropertySorting = {
        handle: '> .drag-me',
        update: function (e, ui) {

        },
        stop: function (e, ui) {
            // this callback has the changed model
            var ListsData = $scope.properties;

            angular.forEach(ListsData, function (value, key) {
                value.Sorting = key;
                // console.log(value.MapName + ' - ' + key);
            });

            // Prepare object to send to server, without extra data
            var SendToServer = [];
            angular.copy(ListsData, SendToServer);

            angular.forEach(SendToServer, function (value, key) {
                value.groups = [];
            });

            $http.post(Root + "?action=ListSorting", SendToServer).then(function (response) {

            }, function (response) {
                // Error
                // Bring up an error message when there is a problem contacting the server
            });
        }
    };

    // Group Sorting
    $scope.groupSorting = {
        handle: '> .drag-me',
        update: function (e, ui) {

        },
        stop: function (e, ui) {
            // this callback has the changed model           
            var parentIndex = ui.item.closest('.property-item').data('index');
            var Groups = $scope.properties[parentIndex].groups;


            angular.forEach(Groups, function (value, key) {
                value.Sorting = key+1;
                // return console.log(value.GroupName + ' -  ' + value.Sorting);
            });

            // Prepare object to send to server, without extra data
            var SendToServer = [];
            angular.copy(Groups, SendToServer);

            angular.forEach(SendToServer, function (value, key) {
                value.filters = [];
            });

            $http.post(Root + "?action=GroupSorting", SendToServer).then(function (response) {

            }, function (response) {
                // Error
                // Bring up an error message when there is a problem contacting the server
            });

            var filters = [];
            angular.forEach(Groups, function (value, key) {

                angular.forEach(value.filters, function (value1, key1) {

                    //Row Sorting
                    var SortingNo = "";
                    var parentGroupSorting = key + 1;
                    if (key1+1 < 10) SortingNo = parentGroupSorting + "00";
                    else if (key1+1 < 100) SortingNo = parentGroupSorting + "0";
                    else if (key1+1 < 1000) SortingNo = parentGroupSorting;

                    value1.Sorting = SortingNo + (key1 + 1);
                    filters.push(value1);                  
                   
                });                
            });
           var SendToServer1 = [];
           angular.copy(filters, SendToServer1);

           $http.post(Root + "?action=RowSorting", SendToServer1).then(function (response) {

           }, function (response) {
               // Error
               // Bring up an error message when there is a problem contacting the server
           });

        }
    };

    // Row Sorting
    $scope.FilterSorting = {
        handle: '> .drag-me',
        update: function (e, ui) {

        },
        stop: function (e, ui) {

            // this callback has the changed model
            var parentList = ui.item.closest('.property-item').data('index');
            var parentGroup = ui.item.closest('.group-item').index();
            var Filters = $scope.properties[parentList].groups[parentGroup].filters;
            
            angular.forEach(Filters, function (value, key) {
               
                var SortingNo = "";
                var parentGroupSorting = parentGroup + 1;
                if (key+1 < 10) SortingNo = parentGroupSorting + "00";
                else if (key+1 < 100) SortingNo = parentGroupSorting + "0";
                else if (key+1 < 1000) SortingNo = parentGroupSorting + "";

                value.Sorting = SortingNo + (key+1);
                 // return console.log(value.Name + ' -  ' + value.Sorting);
            });

            // Prepare object to send to server, without extra data
            var SendToServer = [];
            angular.copy(Filters, SendToServer);

            $http.post(Root + "?action=RowSorting", SendToServer).then(function (response) {

            }, function (response) {
                // Error
                // Bring up an error message when there is a problem contacting the server
            });
        }
    };




    ////// Group handing in listview //////

    // add group
    $scope.addgroup = function (index) {

        if ($scope.properties[index].groups == undefined) {
            $scope.properties[index].groups = [];
        }

        $scope.inserted = {
            // Create child node for Filters items
            filters: []
        };

        $scope.properties[index].groups.push($scope.inserted);
    };


    // Save group
    $scope.savegroup = function (data, group, property) {

        if (data.GroupName == undefined) {
            return "Please enter a name";
        }

        inserted = {
            Active: 1,
            Sorting: property.groups.length
        };
        angular.extend(data, inserted);

        // Create, when there is no UserID from the server saved to the local model, its a new group to be saved
        if (group.UserID == undefined) {
            $http.post(Root + "?action=GroupCreate&Parent=" + property.ID, data).
                then(function (response) {
                    // Success, local model group with data from server
                    angular.extend(group, response.data);
                    return "Saved";
                }, function (response) {
                    // Error
                    return "Error";
                });

            // Update, if there is already a UserID from the server set in the local model
        } else {

            $http.post(Root + "?action=GroupUpdate", group).
                     then(function (response) {
                         // Success, save server data to local model group
                         angular.extend(group, response.data);
                         return "Saved";
                     }, function (response) {
                         // Error
                         return "Error";
                     });
        }
    };



    // Cancel button 
    $scope.cancelGroup = function (index, parentIndex) {

        // Check if the field is empty and remove it .. 
        var thisGroup = $scope.properties[parentIndex].groups[index];

        if (thisGroup.GroupName == undefined) {
            $scope.properties[parentIndex].groups.splice(index, 1);
        }
    }

    // remove group
    $scope.removegroup = function (index, parentIndex, id) {
        isSave = false;
        var bid = $scope.properties[parentIndex].groups[index].ID;
        if ($scope.properties[parentIndex].groups[index].UserID) {
            $http.post(Root + "?action=GroupDelete&id=" + bid);
        }
        $scope.properties[parentIndex].groups.splice(index, 1);
    };



    // Check if not Complete filter details (Item number)
    var NotComplete;
    $scope.checkComplete_List = function (index) {
       
        var listItem = $scope.properties[index];
        var NotComplete = false;
        var count = 0;


        if (listItem.groups !== null) {

            if (listItem.groups.filters !== null) {

                // Check if there is one array with empty "Itemnumber"
                for (var i = 0; i < listItem.groups.length; i++) {

                    angular.forEach(listItem.groups[i].filters, function (value, key) {
                        if (value.Itemnumber == null) {
                            NotComplete = true;
                        };
                        if (value.Quantity === 0 || value.Quantity == null) {
                            NotComplete = true;
                          
                             
                              
                        };
                    });
                }

            }

            // And IF no Filters in Groups also its not complete 
            for (var i = 0; i < listItem.groups.length; i++) {
                if (listItem.groups[i].filters == null) {
                    NotComplete = true;

                    // console.log("not complete: A  ");
                };

       
            }

        } else {
            // If no groups also its not complete 
            NotComplete = true;
        }

        // then ..
        if (NotComplete) {
           
            return 'not-complete';
        }


    };

    //Filter Group (Building) validation. Checks that the Quantity is not 0 and that it contains a product
    $scope.checkComplete_Group = function (index, parent) {
        
        var listItem = $scope.properties[parent];
        var NotComplete = false;

        if (listItem.groups !== null) {

            if (listItem.groups[index].filters !== null) {

                for (var i = 0; i < listItem.groups[index].filters.length; i++) {

                    if (listItem.groups[index].filters[i].Itemnumber == null) {
                        NotComplete = true;
                    };
               
                    if (listItem.groups[index].filters[i].Quantity === 0 || listItem.groups[index].filters[i].Quantity == null) {
                        NotComplete = true;
                    };

                }

            } else {
                // If no Filters also its not complete 
                NotComplete = true;
            }
        }

        // Then ..
        if (NotComplete) {
            return 'not-complete';
        }
    };


    $scope.checkComplete_Filter = function (index, parent, parentParent) {
        var listItem = $scope.properties[parentParent];
        var NotComplete = false;



        if (listItem.groups !== null) {

            if (listItem.groups[parent].filters !== null) {
                if (listItem.groups[parent].filters[index].Itemnumber == null) {
                    NotComplete = true;
                };

                if (listItem.groups[parent].filters[index].Quantity === 0 || listItem.groups[parent].filters[index].Quantity == null) {
                    NotComplete = true;
                };

            } else {
                // If no Filters also its not complete 
                NotComplete = true;
            }
        }

        // Then ..
        if (NotComplete) {
            return 'not-complete';
        }
    };







    // Expanded - Uses to unbind the groups until Toggle expanding the list.
    $scope.toCollapsegroups = function(me, index) {
        var parent = $(me.currentTarget).parents('.property-item');
        parent.find('.groups-wrap.collapse-me, .group-btns.collapse-me').toggle();

        
        $scope.$on('ngRepeatFinished', function(ngRepeatFinishedEvent) {
            //you also get the actual event object
            //do stuff, execute functions -- whatever...
        });
        
    };



    ////// Open Modals from listview //////

    // Row Create Modal
    $scope.openModal = function (index, parentIndex) {

        // Note: this (scope: $scope) and next two lines is to handle the Scope in the modal controller
        $scope.filterItem = {};
        $scope.select = {};

        var modalInstance = $modal.open({
            animation: false,
            templateUrl: 'myModalContent.html',
            controller: 'ModalInstanceCtrl',
            scope: $scope,
            size: 'lg',

            resolve: {
                filters: function () {
                    return $scope.properties[parentIndex].groups[index].filters;
                },
                group: function () {
                    return $scope.properties[parentIndex].groups[index];
                },
                property: function () {
                    return $scope.properties[parentIndex];
                }
            }
        });
    };


    // Row Update, Delete Modal
    $scope.editModal = function (index, parentIndex, parentParentIndex) {

        $scope.filterItem = {};
        $scope.select = {};

        var modalInstance = $modal.open({
            animation: false,
            templateUrl: 'myModalContent_edit.html',
            controller: 'ModalEditCtrl',
            scope: $scope,
            size: 'lg',

            resolve: {
                filterList: function () {
                    return $scope.properties[parentParentIndex].groups[parentIndex].filters;
                },
                currentFilter: function () {
                    return $scope.properties[parentParentIndex].groups[parentIndex].filters[index];
                },
                currentParent: function () {
                    return $scope.properties[parentParentIndex].groups[parentIndex].ID;
                },
                group: function () {
                    return $scope.properties[parentParentIndex].groups[parentIndex];
                },
                property: function () {
                    return $scope.properties[parentParentIndex];
                },
                index: function () {
                    return index
                }
            }
        });
    };



    // List Create Modal
    $scope.AddListModal = function (index) {

        $scope.List = {};

        var modalInstans = $modal.open({
            animation: false,
            templateUrl: 'ModalList_Create.html',
            controller: 'ModalListCreateCtrl',
            scope: $scope,
            size: 'lg',

            resolve: {
                currentIndex: function () {
                    return index;
                },
                propertiesList: function () {
                  
                  if ($scope.properties == null) {
                    $scope.properties = [];
                  }

                  return $scope.properties;

                },
                Addresses: function () {
                    return $scope.addresses;
                }
            }
        });
    }



    // List Update, Delete Modal
    $scope.editListModal = function (index) {
        $scope.List = {};
        var modalInstans = $modal.open({
            animation: false,
            templateUrl: 'ModalList_eddit.html',
            controller: 'ModalListEditCtrl',
            scope: $scope,
            size: 'lg',

            resolve: {
                currentIndex: function () {
                    return index;
                },
                propertiesList: function () {
                    return $scope.properties;
                },
                Addresses: function () {
                    return $scope.addresses;
                }
            }
        });
    }


    // Row Create Copy
    $scope.selectRow = function (index, parentIndex, selected) {
        $scope.filterRows = $scope.properties[parentIndex].groups[index].filters;
        $scope.inserted = selected;

        //Row Sorting
        var SortingNo = "";
        var parentGroupSorting = index+1;
        
        if ($scope.filterRows.length + 1 < 10) SortingNo = parentGroupSorting + "00";
        else if ($scope.filterRows.length + 1 < 100) SortingNo = parentGroupSorting + "0";
        else if ($scope.filterRows.length + 1 < 1000) SortingNo = parentGroupSorting;
        SortingNo = SortingNo + ($scope.filterRows.length + 1);
        $scope.inserted.Sorting = SortingNo;
        //end sorting

        var dbrow = {};
        $http.post(Root + "?action=RowCreate&Parent=" + $scope.properties[parentIndex].groups[index].ID, $scope.inserted)
        .then(function (response) {
            dbrow = response.data;
            $scope.filterRows.push(dbrow);
        }, function (response) {
            //Error...
        });
    };

    $scope.PrintPDFFilterList = function (visibleElementID, printPDFCssFilePath, $event, item, customerNo, Company) {
        var printSource = '';
		
        var itemDisplay = '<table class="pdf-table-filterlist">\
                                    <tr>\
                                        <th>' + pdfItemNumber + '</th>\
                                        <th>' + pdfName + '</th>\
                                        <th>' + pdfObject + '</th>\
                                        <th>' + pdfInstallationNo + '</th>\
                                        <th>' + pdfModel + '</th>\
                                        <th>' + pdfQuantity + '</th>\
                                    </tr>' 
                                    + getItemsInHTML(item) +
                            '</table>';

        var ReferenceName = item.property.Contact + "/" + item.property.ContactID;
        if (ReferenceName.trim() == "/") ReferenceName = "";

          var headerdetails = { 'FilterListLabel': encodeURIComponent(pdfFilterList), 'HeaderNo': encodeURIComponent(item.property.MapNumber), 'Company': encodeURIComponent(Company), 'CompanyTitle': encodeURIComponent(pdfCompanyTitle), 'FilterMapNameTitle': encodeURIComponent(pdfFilterMapNameTitle), 'ReferenceTitle': encodeURIComponent(pdfReferenceTitle), 'CustomerNoTitle': encodeURIComponent(pdfCustomerNoTitle), 'FilterMapName':encodeURIComponent( item.property.MapName), 'Reference': encodeURIComponent(ReferenceName), 'CustomerNo': encodeURIComponent(customerNo), 'DateTitle':encodeURIComponent( pdfDateTitle) }

        printPDF(visibleElementID, printPDFCssFilePath, $event.target, "FilterLists", itemDisplay, headerdetails);
        // console.log(itemDisplay);

        $($event.target).parent().hide();
        $($event.target).parent().parent().find('.fa-spin').css('display', 'inline-block');
        $(document).ajaxComplete(function () {
            setTimeout(function () {
                $($event.target).parent().show();
                $($event.target).parent().parent().find('.fa-spin').hide();
            }, 1000);
        });
    }

    //FilterlistDelete
    $scope.DeleteAllFilterList = function () {
        $("#ErrorMsgContainer").hide();
        var mapNumber = $("input[name='DeleteMapNumber']").val();
        $("#DeleteMapNumberBtn").prop("disabled", true);
        $scope.deleting = true;

        $.ajax({
            url: location.protocol + "//" + location.host + "/Airfiltertool/FilterMapHandler.ashx?mapNumber=" + mapNumber,
            success: function () {
                $("#DeleteMapNumberBtn").prop("disabled", true);
                $("input[name='DeleteMapNumber']").val("");
                $scope.deleting = false;
                $("#SuccessMsgContainer")
                    .show()
                    .fadeOut(3000)
                    .promise()
                    .done(function() {
                        location.reload();
                    });
            },
            error: function () {
                $("#ErrorMsgContainer").show();
                $("#DeleteMapNumberBtn").prop("disabled", false);
                $scope.deleting = false;
            }
        });

    };


});

//Return the items in <tr> row
function getItemsInHTML(currentFilter) {
    var htmlString = '';
    if (currentFilter.property.groups != null) {
        $.each(currentFilter.property.groups, function (groupKey, groupItem) {
            if (groupItem.filters != null) {
                $.each(groupItem.filters, function (key, item) {
                    htmlString += '<tr><td>' + item.Itemnumber + '</td>' + '<td>' + item.Name + '</td>' + '<td>' + (item.Object == null ? "" : item.Object) + '</td>' + '<td>' + (item.Place == null ? "" : item.Place) + '</td>' + '<td>' + (item.InstallNo == null ? "" : item.InstallNo) + '</td>' + '<td>' + (item.Quantity == null ? "" : item.Quantity) + '</td></tr>';
                });
            }
        });
    }
    return htmlString;
}


// List Create Modal Controller
app.controller('ModalListCreateCtrl', function ($scope, $modalInstance, $http, currentIndex, propertiesList, Addresses) {
    // model for modal data
    currentIndex = currentIndex;
    propertiesList = propertiesList;


    $scope.list = {
        UserID: "",
        MapNumber: "",
        MapName: "",
        CustomerNumber: "",
        ContactID: "",
        Contact: "",
        Telephone: "",
        Email: "",
        Notes: "",
        DeliveryID: "",
        DeliveryName: "",
        DeliveryStreet: "",
        DeliveryZipCode: "",
        DeliveryCity: "",
        LastOrderDate: "",
        ReminderNextOrderDate: "",
        ReminderInterval: 0,
        EditDate: "",
        groups: []
    };

    // Delivery addresses
    $scope.Addresses = Addresses;

    $scope.setAddress = function (me) {
        $scope.list.DeliveryID = me;
        $scope.list.DeliveryName = $scope.Addresses[me].Name;
        $scope.list.DeliveryStreet = $scope.Addresses[me].Street;
        $scope.list.DeliveryZipCode = $scope.Addresses[me].Zip;
        $scope.list.DeliveryCity = $scope.Addresses[me].City;
    };


    $scope.saveMlist = function () {
        
        inserted = {
            Active: 1,
            Sorting: propertiesList.length,
            UserID: 0,
        };

        angular.extend($scope.list, inserted);

        $http.post(Root + "?action=ListCreate", $scope.list)
            .then(function (response) {


                setTimeout(function () {

                    if (propertiesList.length == 0) {
                        location.reload();
                        // console.log('First and only');
                    } else {
                        // console.log('there is more than one');
                        angular.extend($scope.list, response.data);
                        propertiesList.push($scope.list);
                        $modalInstance.close();
                    }
                }, 100)


            }, function (response) {
                // Error
            });



        // If first list reload the page
        // TODO: The new users with no lists should render the ajax to contain object form [] not Null as now

    };



    $scope.cancel = function () {
        $modalInstance.close();
    };

});



// List Update, Delete Modal Controller
app.controller('ModalListEditCtrl', function ($scope, $modalInstance, $http, currentIndex, propertiesList, Addresses, $filter) {

    // model for modal data
    currentIndex = currentIndex;
    propertiesList = propertiesList;
    $scope.list = propertiesList[currentIndex];
    $scope.CustomerNumber = [];
    $scope.CustomerNumber.nr = "";
    $scope.CustomerNumber.Error = false;



    // Delivery addresses
    $scope.Addresses = Addresses;

    $scope.setAddress = function (me) {
        $scope.list.DeliveryID = me;
        $scope.list.DeliveryName = $scope.Addresses[me].Name;
        $scope.list.DeliveryStreet = $scope.Addresses[me].Street;
        $scope.list.DeliveryZipCode = $scope.Addresses[me].Zip;
        $scope.list.DeliveryCity = $scope.Addresses[me].City;
    };


    // Change logs
    $http.get(Root + "?action=RowHistoryRead&id=" + $scope.list.ID).success(function (data) {
        $scope.logs = data;
    });



    // "Senast beställd" Date format 
    $scope.$watch('list.LastOrderDate', function (newValue) {
        $scope.list.LastOrderDate = $filter('date')(newValue, 'yyyy-MM-dd');
    });



    $scope.UpdateMlist = function () {

        savelist = {
            ID: $scope.list.ID,
            MapNumber: $scope.list.MapNumber,
            MapName: $scope.list.MapName,
            CustomerNumber: $scope.list.CustomerNumber,
            ContactID: $scope.list.ContactID,
            Contact: $scope.list.Contact,
            Telephone: $scope.list.Telephone,
            Email: $scope.list.Email,
            Notes: $scope.list.Notes,
            DeliveryName: $scope.list.DeliveryName,
            DeliveryStreet: $scope.list.DeliveryStreet,
            DeliveryZipCode: $scope.list.DeliveryZipCode,
            DeliveryCity: $scope.list.DeliveryCity,
            LastOrderDate: $scope.list.LastOrderDate,
            ReminderNextOrderDate: $scope.list.ReminderNextOrderDate,
            ReminderInterval: $scope.list.ReminderInterval,
            Sorting: $scope.list.Sorting
        }

        neWID = 0;
        $http.post(Root + "?action=ListUpdate", savelist).
           then(function (response) {
               neWID = response.data.ID;
               angular.extend(propertiesList[currentIndex], propertiesList[currentIndex].ID);
               propertiesList[currentIndex].ID = neWID;
               $modalInstance.close();
           }, function (response) {
               // Error
           });
    };

    $scope.DeletList = function () {
        isSave = false;
        $http.post(Root + "?action=ListDelete&id=" + propertiesList[currentIndex].ID, { Id: propertiesList[currentIndex].ID, parentID: propertiesList[currentIndex].parentIndex }).
        then(function (response) {

        }, function (response) {
            // Error
        });
        propertiesList.splice(currentIndex, 1);
        

        setTimeout(function(){
          location.reload();
        }, 100)

        // $modalInstance.close();
    };

    $scope.MoveList = function () {
        isSave = false;
        $http.post(Root + "?action=MoveList&id=" + propertiesList[currentIndex].ID + "&customerNo=" + $scope.CustomerNumber.nr).
        then(function (response) {
            if (response.data["status"] == "true") {
                propertiesList.splice(currentIndex, 1);
                $modalInstance.close();
                alert('Listan har flyttats till kundnummer: ' + $scope.CustomerNumber.nr);
            }
            else {
                $scope.CustomerNumber.Error = true;
            }
        }, function (response) {
            // Error
        });
    };


    $scope.ListDateSet = function (value) {
        setDate = {
            ID: $scope.list.ID,
            MapNumber: $scope.list.MapNumber,
            MapName: $scope.list.MapName,
            CustomerNumber: $scope.list.CustomerNumber,
            ContactID: $scope.list.ContactID,
            Contact: $scope.list.Contact,
            Telephone: $scope.list.Telephone,
            Email: $scope.list.Email,
            Notes: $scope.list.Notes,
            DeliveryName: $scope.list.DeliveryName,
            DeliveryStreet: $scope.list.DeliveryStreet,
            DeliveryZipCode: $scope.list.DeliveryZipCode,
            DeliveryCity: $scope.list.DeliveryCity,
            LastOrderDate: $scope.list.LastOrderDate,
            ReminderNextOrderDate: $scope.list.ReminderNextOrderDate,
            ReminderInterval: $scope.list.ReminderInterval,
            Sorting: $scope.list.Sorting
        }


        if ($scope.list.ReminderInterval != 0) {

            $http.post(Root + "?action=ListDateSet", setDate).
               then(function (response) {

                   // neWID = response.data.ID;

                   $scope.list = response.data;

                   // angular.extend(propertiesList[currentIndex], propertiesList[currentIndex].ReminderNextOrderDate);
                   propertiesList[currentIndex].ReminderNextOrderDate = $scope.list.ReminderNextOrderDate;
                   // propertiesList[currentIndex].ID = neWID;
               }, function (response) {
                   // Error
               });
        } else {
            $scope.list.ReminderNextOrderDate = null;
        }
    }


    $scope.cancel = function () {
        $modalInstance.close();
    };

});




// Row Create Modal Controller
app.controller('ModalInstanceCtrl', function ($scope, $modalInstance, filters, group, property, $cookies, $http, $timeout) {
    // console.log('here');
    $scope.filterItem = {};

    // Init the product list
    $scope.products = [{ id: null, modell: "Sök och välj" }];
    $scope.searchTerm = { term: "", searchError: "" };


    // Getting the group and property "name" to add Filter on ..
    $scope.filterItem.Object = group.GroupName;
    $scope.filterItem.propertyName = property.MapName;
 

    var lengt = 1;

    if (filters) {
        lengt = filters.length + 1;
    } else {
        filters = [];
    }

    //Search Product based on needs 
    $scope.searchBasedNeeds = function () {
  
        var needProductGroup = $scope.filterItem.NeedProductGroup == null ? "" : $scope.filterItem.NeedProductGroup;
        var neededFilterClass = $scope.filterItem.NeededFilterClass == null ? "" : $scope.filterItem.NeededFilterClass;
        var neededWidth = $scope.filterItem.NeededWidth == null ? "" : $scope.filterItem.NeededWidth;
        var neededHeight = $scope.filterItem.NeededHeight == null ? "" : $scope.filterItem.NeededHeight;
        var neededDepth = $scope.filterItem.NeededDepth == null ? "" : $scope.filterItem.NeededDepth;

        if (neededWidth.length > 0) neededWidth = neededWidth.substring(0, neededWidth.length - 1) + "?";
        if (neededHeight.length > 0) neededHeight = neededHeight.substring(0, neededHeight.length - 1) + "?";
        if (neededDepth.length > 0) neededDepth = neededDepth.substring(0, neededDepth.length - 1) + "?";

        var searchstring;
        if (neededDepth !== "") {
            searchstring = needProductGroup + " " + neededFilterClass + " *" + neededWidth + "x" + neededHeight + "x" + neededDepth ;
        } else {
            searchstring = needProductGroup + " " + neededFilterClass + " *" + neededWidth + "x" + neededHeight + "*";
        }

        $scope.searchTerm.term = searchstring;
    }

    $scope.UpdateSelected = function (index, type) {
        // console.log(index + type);
        var currentProduct = null;

            productID = index;
            // sharing the productID value with : $scope.select.productsOptions
            $scope.select.productsOptions = productID;

            if (type == "favorite") {
                currentProduct = $scope.favorites[productID];
            } else {
                currentProduct = $scope.products[productID];
            }

            updated = {
                Itemnumber: currentProduct.Itemnumber,
                ProductID: currentProduct.ProductID,
                Name: currentProduct.Name,
                Model: currentProduct.Model,
                Media: currentProduct.Media,
                Width: currentProduct.Width,
                Height: currentProduct.Height,
                Depth: currentProduct.Depth,
                Framematerial: currentProduct.Framematerial,
                Faceguard: currentProduct.Faceguard,
                FaceguardPosition: currentProduct.FaceguardPosition,
                Gaskettype: currentProduct.Gaskettype,
                Gasketlocation: currentProduct.Gasketlocation,
                Headerdepth: currentProduct.Headerdepth,
                Headermaterial: currentProduct.Headermaterial,
                Pockets: currentProduct.Pockets,
                Price: currentProduct.Price,
                LabelAndPacking: currentProduct.LabelAndPacking,
                Capacity: currentProduct.Capacity,
                Test: currentProduct.Test,
                Special: currentProduct.Special

        };

            angular.extend($scope.filterItem, updated);

            // Hide the results
            $('.filter-search-results').hide();
            $('.filter-form-summary').show();


    }


    $scope.addFilter = function (data, productsOptions) {
 
        filters = filters;
        group = group;
        property = property;

        // Set default ANTAL = 1 if not added
        if ($scope.filterItem.Quantity == undefined || $scope.filterItem.Quantity == null || $scope.filterItem.Quantity === "" || $scope.filterItem.Quantity === "0" || $scope.filterItem.Quantity === 0) {
            $scope.filterItem.Quantity = 0;
            catchReturnFromaddFilter();

            return;
        }

        //Row Sorting
        var SortingNo = "";
        var parentGroupSorting = group.Sorting;
        if (group.filters != null) {

            if (group.filters.length + 1 < 10) SortingNo = parentGroupSorting + "00";
            else if (group.filters.length + 1 < 100) SortingNo = parentGroupSorting + "0";
            else if (group.filters.length + 1 < 1000) SortingNo = parentGroupSorting;
            SortingNo = SortingNo + (group.filters.length + 1);
        } else {
            SortingNo = parentGroupSorting + "00" + 1;
        }
       
      
        if (group.filters != null) {
            inserted = {
                Active: 1,
                Sorting: SortingNo,
                ListID: property.ID,
                GroupID: group.ID,
                Quantity: $scope.filterItem.Quantity
            }
        } else {
            inserted = {
                Active: 1,
                Sorting: SortingNo,
                ListID: property.ID,
                GroupID: group.ID,
                Quantity: $scope.filterItem.Quantity
            }
        }
        angular.extend($scope.filterItem, inserted);

        $http.post(Root + "?action=RowCreate&Parent=" + group.ID, $scope.filterItem)
        .then(function (response) {
            //$scope.filterItem = response.data.ID;
            angular.extend($scope.filterItem, response.data);
        }, function (response) {
            //Error
        });



        filters.push($scope.filterItem);
        group.filters = filters;

        $modalInstance.close();
    };

    $scope.AdvSearch = function () {
        //filters = filters;
        group = group;
        property = property;

        inserted = {
            Active: 1,
            Sorting: 0,
            ListID: property.ID,
            GroupID: group.ID
        };

        angular.extend($scope.filterItem, inserted);

        $http.post(Root + "?action=RowCreate&Parent=" + group.ID, $scope.filterItem)
      .then(function (response) {
          angular.extend($scope.filterItem, response.data);
          var url = "/default.aspx?ID=2" //&Freetext=" + $scope.searchTerm.term;
          $cookies.put('GroupID', group.ID);
          $cookies.put('RowID', $scope.filterItem.ID);
          $cookies.put('ListID', group.ListID);
          // send user to page .
          window.location = url;
          //$location.path(url);
      }, function (response) {
          // Error
      });
    }



    // Search products in the modal 
    $scope.$watch('searchTerm.term', function (val) {
        var tempFilterText = val;
  
        // If detect search query
        if (tempFilterText !== "") {
            $scope.SearchQuery = tempFilterText;

            $http.get(RootProducts + "?action=search&term=" + $scope.SearchQuery).success(function (data) {

                $scope.searchTerm.resultState = "true";

                if (data.length < 1) {
                    $scope.products = false;
                    $scope.searchTerm.errorClass = "no-results";
                }
                else {
                    $scope.products = data;
                    $scope.searchTerm.errorClass = "";
                }
            });
        }

        // Show the results
        $('.filter-search-results').show();
    })


    $scope.SearchFilters = function () {

        $http.get(RootProducts + "?action=search&term=" + $scope.searchTerm.term).success(function (data) {

            $scope.searchTerm.resultState = "true";

            if (data.length < 1) {
            }
            else {
                $scope.products = data;
            }
        });
    };


    // Get favorites
    var favProductIDs = document.getElementById("favoritesNumbers").innerHTML;
    if (favProductIDs !== "") {
        $http.get(RootProducts + "?action=searchnumbers&term=" + favProductIDs).success(function (data) {
            var favoriteLists = new Array();
            var productIDs = favProductIDs.trim().split(" ");
            
            $.each(data, function (key, value) {
                favoriteLists.push(value);
            });

            $scope.favorites = favoriteLists;
        });
    }


    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

});



// Row Update, Delete Modal Controller
app.controller('ModalEditCtrl', function ($scope, $modalInstance, filterList, currentFilter, currentParent, group, property, $http, $cookies, index) {

    listid = currentParent.ID;
    rowID = currentFilter.RowID;
    $scope.filterItem = currentFilter;

    // Init the product list
    $scope.products = [{ id: null, modell: "" }];
    $scope.searchTerm = { term: "", searchError: "" };

    // Search results review
    $scope.review = false;

    // Getting the group and property "name" to add Filter on ..
    $scope.filterItem.Object = group.GroupName;
    $scope.filterItem.propertyName = property.MapName;

    //Search Product based on needs
    $scope.searchBasedNeeds = function () {

        var needProductGroup = $scope.filterItem.NeedProductGroup == null ? "" : $scope.filterItem.NeedProductGroup;
        var neededFilterClass = $scope.filterItem.NeededFilterClass == null ? "" : $scope.filterItem.NeededFilterClass;
        var neededWidth = $scope.filterItem.NeededWidth == null ? "" : $scope.filterItem.NeededWidth;
        var neededHeight = $scope.filterItem.NeededHeight == null ? "" : $scope.filterItem.NeededHeight;
        var neededDepth = $scope.filterItem.NeededDepth == null ? "" : $scope.filterItem.NeededDepth;

        if (neededWidth.length > 0) neededWidth = neededWidth.substring(0, neededWidth.length - 1) + "?";
        if (neededHeight.length > 0) neededHeight = neededHeight.substring(0, neededHeight.length - 1) + "?";
        if (neededDepth.length > 0) neededDepth = neededDepth.substring(0, neededDepth.length - 1) + "?";

        var searchstring;
        if (neededDepth !== "") {
            searchstring = needProductGroup + " " + neededFilterClass + " *" + neededWidth + "x" + neededHeight + "x" + neededDepth ;
        } else {
            searchstring = needProductGroup + " " + neededFilterClass + " *" + neededWidth + "x" + neededHeight + "*";
        }
        $scope.searchTerm.term = searchstring;
    }

    // Set product data from selected
    $scope.UpdateSelected = function (index, type) {

        var currentProduct = null;
        productID = index;
        // sharing the productID value with : $scope.select.productsOptions
        $scope.select.productsOptions = productID;

        if (type == "favorite") {
            currentProduct = $scope.favorites[productID];
        } else {
            currentProduct = $scope.products[productID];
        }

        updated = {
            Itemnumber: currentProduct.Itemnumber,
            ProductID: currentProduct.ProductID,
            Name: currentProduct.Name,

            Framematerial: currentProduct.Framematerial,
            Headerdepth: currentProduct.Headerdepth,
            Gaskettype: currentProduct.Gaskettype,
            Gasketlocation: currentProduct.Gasketlocation,
            Faceguard: currentProduct.Faceguard,
            FaceguardPosition: currentProduct.FaceguardPosition,
            LabelAndPacking: currentProduct.LabelAndPacking,
            Capacity: currentProduct.Capacity,
            Pockets: currentProduct.Pockets,
            Test: currentProduct.Test,
            Special: currentProduct.Special
          
    };

        // the search results review (before saving)..
        if ($scope.filterItem.Itemnumber !== currentProduct.Itemnumber) {

            $scope.review = true;
            $scope.filterItem.reviewItemnumber = currentProduct.Itemnumber,
            $scope.filterItem.reviewProductID = currentProduct.ProductID,
   
            $scope.filterItem.reviewFramematerial = currentProduct.Framematerial,
            $scope.filterItem.reviewHeaderdepth = currentProduct.Headerdepth,          
            $scope.filterItem.reviewGaskettype = currentProduct.Gaskettype,
            $scope.filterItem.reviewGasketlocation = currentProduct.Gasketlocation,
            $scope.filterItem.reviewFaceguard = currentProduct.Faceguard,
            $scope.filterItem.reviewFaceguardPosition = currentProduct.FaceguardPosition,
            $scope.filterItem.reviewLabelAndPacking = currentProduct.LabelAndPacking,
            $scope.filterItem.reviewCapacity = currentProduct.Capacity,
            $scope.filterItem.reviewPockets = currentProduct.Pockets,
            $scope.filterItem.reviewTest = currentProduct.Test,
            $scope.filterItem.reviewSpecial = currentProduct.Special

        }

        // Hide the results
        $('.filter-search-results').hide();
        $('.filter-productdata').show();

        return updated;
    }

    $scope.AdvSearch = function () {

        //filters = filters;
        group = group;
        property = property;

        inserted = {
            Active: 1,
            Sorting: 0,
            ListID: property.ID,
            GroupID: group.ID
        };

        angular.extend($scope.filterItem, inserted);

        $http.post(Root + "?action=RowUpdate&Parent=" + group.ID, $scope.filterItem)
      .then(function (response) {
          angular.extend($scope.filterItem, response.data);
          var url = "/default.aspx?ID=2" //&Freetext=" + $scope.searchTerm.term;
          $cookies.put('GroupID', group.ID);
          $cookies.put('RowID', $scope.filterItem.ID);
          $cookies.put('ListID', group.ListID);
          // send user to page .
          window.location = url;
          //$location.path(url);
      }, function (response) {
          // Error
      });
    }


    $scope.editFilter = function (data, productsOptions) {

        // Check if you hit search products and got updated info
        if ($scope.review !== false) {
            // Extend the Product that selected by Search function
            angular.extend($scope.filterItem, updated);
        }

        // Set default ANTAL = 1 if not added
        if ($scope.filterItem.Quantity == undefined || $scope.filterItem.Quantity == null || $scope.filterItem.Quantity === "" || $scope.filterItem.Quantity === "0" || $scope.filterItem.Quantity === 0) {
            $scope.filterItem.Quantity = 0;
            catchReturnFromaddFilter();
            return;

        }

        inserted = {
            Active: 1,
            Quantity: $scope.filterItem.Quantity
        };
        

        angular.extend($scope.filterItem, inserted);

        $http.post(Root + "?action=RowUpdate&Parent=" + group.ID, $scope.filterItem)
        .then(function (response) {
            angular.extend($scope.filterItem, response.data);
        }, function (response) {
            // Error
            });

        $modalInstance.close();
    };


    // Search products in the modal 
    $scope.$watch('searchTerm.term', function (val) {
        var tempFilterText = val;

        // If detect search query
        if (tempFilterText !== "") {
            $scope.SearchQuery = tempFilterText;

            $http.get(RootProducts + "?action=search&term=" + $scope.SearchQuery).success(function (data) {

                $scope.searchTerm.resultState = "true";

                if (data.length < 1) {
                    $scope.products = false;
                    $scope.searchTerm.errorClass = "no-results";
                }
                else {
                    $scope.products = data;
                    $scope.searchTerm.errorClass = "";
                }
            });
        }
        // Show the results
        $('.filter-search-results').show();
    })

    $scope.SearchFilters = function () {
        $http.get(RootProducts + "?action=search&term=" + $scope.searchTerm.term).success(function (data) {

            $scope.searchTerm.resultState = "true";

            if (data.length < 1) {
            }
            else {
                $scope.products = data;
            }
        });
    };


    // Get favorites
    var favProductIDs = document.getElementById("favoritesNumbers").innerHTML;
    if (favProductIDs !== "") {
        $http.get(RootProducts + "?action=searchnumbers&term=" + favProductIDs).success(function (data) {
            var favoriteLists = new Array();
            var productIDs = favProductIDs.trim().split(" ");
            
            $.each(data, function (key, value) {
                favoriteLists.push(value);
            });

            $scope.favorites = favoriteLists;
        });
    }

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

    $scope.remove = function () {
        $http.post(Root + "?action=RowDelete&id=" + currentFilter.ID)
         .then(function (response) {

         }, function (response) {
             // Error
         });
        filterList.splice(filterList.indexOf(currentFilter), 1);
        $modalInstance.dismiss('cancel');
    };
});



// confirm
app.directive('ngConfirmClick', [
  function () {
      return {
          priority: -1,
          restrict: 'A',
          link: function (scope, element, attrs) {
              element.bind('click', function (e) {
                  var message = attrs.ngConfirmClick;
                  if (message && !confirm(message)) {
                      e.stopImmediatePropagation();
                      e.preventDefault();
                  }
              });
          }
      }
  }
]);


// Filter list Collapse function

// function toCollapsegroups(me) {
//     var parent = $(me).parents('.property-item');
//     parent.find('.groups-wrap.collapse-me, .group-btns.collapse-me').toggle();
//     Vis = true;
//     console.log(Vis);
// };


function toCollapseAll(me) {
    var parent = $(me).parents('.property-item');
    parent.find('.groups-wrap.collapse-me, .collapse-me').toggle();
};

function toCollapseFilters(me) {
    var parent = $(me).parents('.group-item');
    parent.find('.filters-wrap.collapse-me, .filters-btns.collapse-me').toggle();
};

// Modal log collapse
function LogCollapse(me) {
    var parent = $(me).parents('.modal-body');
    parent.find('.log-container').toggle();
}

// Copy Reference (demo)
function CopyReference(me) {
    $(me).find('i.fa').remove();
    $(me).append('<i class="fa fa-check" style="display:inline-block; margin-left:20px; color:green;"></i>');
    return false;
}

// Init All features, functions When Angular builds the Content

function InitAll() {
    $('[data-toggle="tooltip"]').tooltip();
};


// Empty card button link 
function emptyCard_url() {

    var url = window.location.href;
    var empty = 'cartcmd=emptycart';

    if (url.indexOf("?") > -1) {
        $('#DefaultEmptyCartBtn').attr('href', url + '&' + empty);
        } else {
        $('#DefaultEmptyCartBtn').attr('href', url + '?' + empty);
    }
}

// Date picker 
function DatePicker(x) {

    $('.date-picker').datepicker({
        language: "sv",
        format: "yyyy-mm-dd",
        todayBtn: true,
        forceParse: false,
        calendarWeeks: true,
        autoclose: true,
        todayHighlight: true
    });
};
