//Punchout functions

$('#punchoutForm').submit(function (e) {
    var url = location.protocol + "//" + location.host + "/punchoutHandler.ashx?SubmitDataToHookUrl=true";
    console.log("HOOK_URL: " + url);

    $.ajax({
        url: url,
        success: function (result) {
            console.log("Checkout successful, redirect to hook_url: " + result);

            //redirect to hook_url after successful post.
            window.location.href = result;
        },
        error: function (result) {
            $("#punchoutErrorMessage").show();
        }
    });

    e.preventDefault();
});

$('#punchoutEndSession')
    .click(function () {
        EndPunchoutSession("PunchoutSignoutSuccessReload");
    });


function EndPunchoutSession(sessionSuccessReload) {

    $.ajax({
        url: location.protocol + "//" + location.host + "/punchoutHandler.ashx?END_PUNCHOUT=true",
        success: function () {
            sessionStorage.setItem(sessionSuccessReload, "true");
            location.reload();
        },
        error: function () {
            $("#punchoutErrorMessage").show();
        }
    });
}

window.onload = function () {

    var punchoutSignoutSuccessReload = sessionStorage.getItem("PunchoutSignoutSuccessReload");

    if (punchoutSignoutSuccessReload) {
        sessionStorage.removeItem("PunchoutSuccessReload");
        $("#PunchoutSignoutMessage").show();
    }
}