//GLOBAL VALIDATION

//START UK Cusom validation User logIn
$(document).ready(function() {
  var emailInput = $('#UserManagement_Form_Email');
  var userNameInput = $('#UserManagement_Form_UserName');
  var dummyUserNameInput = $('#dummyUserNameInput');
  var errorMessage = $('.field-error');

  //If there is an error message expand the div
  if (errorMessage.length) {
    $('#registrateUserButton2').hide();
    $('.login-box').css('height', '53em');
    $('#submitter')
      .removeClass('hidden')
      .show();
    $('#registerContainer').slideDown();
  }

  dummyUserNameInput.addClass('disabledUserInput');

  emailInput.on('change keyup', function() {
    userNameInput.val(this.value);
    dummyUserNameInput.val(this.value);
  });

  emailInput.focusout(function() {
    userNameInput.val(emailInput.val());
    dummyUserNameInput.val(emailInput.val());
  });
});

var emailInput = $('#UserManagement_Form_Email');
var url =
  location.protocol +
  '//' +
  location.host +
  '/AxAjaxHelper.ashx?AxUserExists=' +
  emailInput.val();

jQuery.validator.addMethod('uniqueUserName', function() {
  var url =
    location.protocol +
    '//' +
    location.host +
    '/AxAjaxHelper.ashx?AxUserExists=' +
    emailInput.val();
  var userIsUnique = false;

  $.ajax({
    url: url,
    async: false,
    success: function(axUserExists) {
      userIsUnique = axUserExists.toLowerCase() === 'true' ? false : true;
    }
  });

  return userIsUnique;
});

$('#UserManagementEditForm').validate({
  rules: {
    UserManagement_Form_Email: {
      uniqueUserName: true
    },
    UserManagement_Form_NewPasswordConfirm: {
      equalTo: '#UserManagement_Form_NewPassword'
    }
  }
});
