var CapoInstantSearchConfig = {
    //searchUrl:"/CapoInstantSearch.aspx?json=1&maxhits=20",
    searchUrl:"/Files/Templates/CapoInstantSearch/CapoInstantSearch.aspx?json=1&maxhits=10",
    
    // Template for each individual result
    // use ${link}, ${img}, ${title}, etc..
    resultTemplate:'<tr>\
            <td class="search-img"><a href="${link}"><img class="lazy dw-search-result-smallimage" data-original="/admin/public/getimage.aspx?Image=/Files/Images/produktbilder/${id}/${id}_1.jpg&Format=jpg&Compression=50&width=50&height=50&altFmImage_path=/Files/Images/NoImageAvailable.png" /></a></td>\
            <td class="nowrap">\
                <a class="dw-search-result-url" href="${link}"><b>${title}</b> <i class="fa fa-file-text-o"><span>${id}</span></i></a>\
            </td>\
        </tr>',
    
    // Template for the paging
    pagingTemplate: '<li><a onclick="$.publish(\'/search/live/page\', [$(this).text(), this]);">${}</a></li>',

    // jQuery selector for the element in the markup which will be filled with templated results
    resultsPopulationElement: "#search-results table tbody",
    
    // jQuery selector for the element which will receive the templated page links for the result list.
    pagingPopulationElement: "#live-paging ol",
    
    // Whole searchbox encolusure, the element which receives "open" class when the box will need to open.
    searchBoxElement: "#search-results",
    
    //vx-7
    LinkElement: "#LinkSpace",


    // Element which receives "no-live-hits" class when there are no hits.
    noHitsElement: "#search-results .dw-no-results"
};

// Base link for "More hits", preserved during multiple searches, just the term changes
var MorehitsBaseHref = $("#link-more-hits").attr('href'); 

// Getting the current script path, as seen in http://stackoverflow.com/questions/8523200/javascript-get-current-filescript-path
// Requires this script to be inserted statically.
var scriptEls = document.getElementsByTagName( 'script' );
var thisScriptEl = scriptEls[scriptEls.length - 1];
var scriptPath = thisScriptEl.src;
var scriptFolder = scriptPath.substr(0, scriptPath.lastIndexOf( '/' )+1 );

// $("head").append('<script type="text/javascript" src="'+scriptFolder+'CapoInstantSearch.config.js"></script>');

$(function() {

	$("body").append('<div id="async-search-background" style="display: none; position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 8999;">\
		<img style="float: left; width: 100%; height: 100%;" src="/Files/Templates/Designs/CapoStarter/assets/img/pxl.gif">\
	</div>');

	$("#search-results").mousedown(function(){
		// $.publish('/search/live/clear');
	});
});
  
function liveSearch() { }

liveSearch.Timer = null;

liveSearch.Settings = {
    "sortorder": "default",
    "pagenr": 1
};

liveSearch.LoadTimer = null;
liveSearch.LoadIcon = function (on) {
    if (on)
        liveSearch.LoadTimer = setTimeout(function () {
            $('#live-results').addClass('loading');
        }, 150);
    else {
        clearTimeout(liveSearch.LoadTimer);
        setTimeout(function () {
            $('#live-results').removeClass('loading');
        }, 150);
    }
};
liveSearch.ActiveTerm = null;
liveSearch.Template = $.template('livetmpl', CapoInstantSearchConfig.resultTemplate);

liveSearch.PagingTemplate = $.template('livepagingtmpl', CapoInstantSearchConfig.pagingTemplate);

liveSearch.enter = function (input) {
    var term = $.trim($(input).val());

    if ($('.search-results').css('display') == 'none') {    
        liveSearch.Timer = setTimeout(function () {
            $("#search-results").show();
            $.publish('/search/live/perform', [term]);
        }, 600);   
    }
};
liveSearch.KeyUp = function (input) {
    var term = $.trim($(input).val());
	if (term==liveSearch.ActiveTerm) return;
    clearTimeout(liveSearch.Timer);
    if (term.length > 2 && term != liveSearch.ActiveTerm) {
        liveSearch.Settings.pagenr = 1;
        liveSearch.ActiveTerm = term;
        liveSearch.Timer = setTimeout(function () {
            $("#search-results").show();
            $.publish('/search/live/perform', [term]);
        }, 600);
    }
    else if (!term) {
        $.publish('/search/live/clear');
    }
};

$.subscribe('/search/live/clear', function () {
    $(CapoInstantSearchConfig.searchBoxElement).removeClass("open");
    $(CapoInstantSearchConfig.pagingPopulationElement).empty();
    liveSearch.ActiveTerm = null;
	$("#search-results").hide();
});

$.subscribe('/search/live/perform', function (term) {
    var $liveList = $(CapoInstantSearchConfig.resultsPopulationElement);
    var $searchBox = $(CapoInstantSearchConfig.searchBoxElement);
    var $noHitsBox = $(CapoInstantSearchConfig.noHitsElement);
    var $Link = $(CapoInstantSearchConfig.LinkElement);
    $liveList.empty();

	$noHitsBox.removeClass("no-live-hits");
    $searchBox.addClass("open");
	


    liveSearch.LoadIcon(true);

    $.getJSON(CapoInstantSearchConfig.searchUrl,
    {
        t: term,
        pagesize: 5,
        pagenr: liveSearch.Settings.pagenr,
        sort: liveSearch.Settings.sortorder,
        imgsize: 'small'
    },
    function (data) {
        liveSearch.LoadIcon(false); // Reset previous searches

        if (data.hits) {
            var pages = new Array();
            var paging = $("#live-paging");
            var pagingList = $(CapoInstantSearchConfig.pagingPopulationElement);

            if (data.hits > 10)
            {                
                //$Link.html('<a href="/Default.aspx?ID=2&Freetext=' + term + '">Visa fler träffar</a>');                
                $("#link-more-hits").attr('href', MorehitsBaseHref + term);
                $("#link-more-hits").css('display', 'block');                               
            }

            $.tmpl("livetmpl", data.items).appendTo($liveList);
            $("#hit-counter").html(data.hits);
            $("#hit-counter").parent().css('display', 'block');
            pagingList.empty();

            if (data.pages > 1) {
                for (var i = 1; i <= data.pages; i++) {
                    pages[i] = i;
                }
                $.tmpl("livepagingtmpl", pages).appendTo(pagingList);
                $("a", pagingList).eq(parseInt(liveSearch.Settings.pagenr) - 1).addClass("active-page");
                paging.show();
            }

            else paging.hide();

            $(".lazy").show().lazyload({
                effect: "fadeIn"
            });

            $('#live-search-word').html(term);
        }
        else {
            $("#hit-counter").parent().css('display', 'none');
            $noHitsBox.addClass("no-live-hits");
        }
    });
});